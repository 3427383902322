import { defineStore } from "pinia";
import { TranslationApiController } from "@/api/TranslationApiController";
import { SortDirection } from "@/enums/SortDirection";
import { TranslationModel } from "@/models/TranslationModel";
import { TranslationListViewModel, TranslationViewModel } from "@/viewModels/ViewModels";
import { TranslationDto, TranslationListDto } from "@/dtos/TranslationDtos";
import { StoreApiProxy } from "@/helpers/StoreApiProxy";
import stringMixins from "@/mixins/stringMixins";
import { ExportType } from "@/enums/ExportType";
import { Language } from "@/enums/Language";
import { TranslationState } from "@/dtos/state/TranslationState";

const apiController = new TranslationApiController();
const storeApiProxy = new StoreApiProxy<TranslationModel, TranslationDto, TranslationListDto, TranslationViewModel, TranslationListViewModel>(
  apiController,
  TranslationListDto.mappings,
  TranslationDto.mappings
);

export const useTranslationStore = defineStore("translation", {
  // =================================================
  // ||                   STATE                     ||
  // =================================================
  state: (): TranslationState => ({
    isTranslationsRestored: false,
    currentLanguage: Language.NONE,
    listDto: {
      list: [],
      filter: null,
      typeFilter: null,
      page: -1,
      sortColumn: null,
      sortDirection: SortDirection.NONE,
      isDirty: false,
      isLoading: false,
      hasError: false,
      totalRecordCount: -1,
      pageCount: -1,
      validationErrorFields: [],
    },
  }),
  // =================================================
  // ||                   GETTERS                   ||
  // =================================================
  getters: {},
  actions: {
    // =================================================
    // ||                   ACTIONS                   ||
    // =================================================

    /* -------------------------------------------
     * Get all translations from API.
     * -------------------------------------------- */
    async getTranslations(listDto: TranslationListDto): Promise<void> {
      return storeApiProxy.apiGetMultiple(listDto);
    },

    /* -------------------------------------------
     * Get all active translations from API.
     * -------------------------------------------- */
    async getActiveTranslations(): Promise<void> {
      return storeApiProxy.apiGetMultipleActive(this.listDto).then(() => {
        this.isTranslationsRestored = true;
      });
    },

    /* -------------------------------------------
     * Get single translation from API.
     * -------------------------------------------- */
    async getTranslation(dto: TranslationDto, id: number): Promise<void> {
      return storeApiProxy.apiGetSingle(dto, id);
    },

    /* -------------------------------------------
     * Saves one translation DTO.
     * -------------------------------------------- */
    async updateTranslation(dto: TranslationDto): Promise<void> {
      return storeApiProxy.apiUpdate(dto);
    },

    /* -------------------------------------------
     * Exports all entities as PDF or XLSX.
     * -------------------------------------------- */
    async exportList(listDto: TranslationListDto, exportType: ExportType): Promise<void> {
      return storeApiProxy.apiExport(listDto, exportType);
    },

    // ===== Mutations =====

    setSortColumn(listDto: TranslationListDto, column: string): void {
      listDto.sortColumn = stringMixins.stripModelString(column);
    },

    setSortDirection(listDto: TranslationListDto, direction: SortDirection): void {
      listDto.sortDirection = direction;
    },

    setPage(listDto: TranslationListDto, page: number): void {
      listDto.page = page;
    },

    setFilter(listDto: TranslationListDto, filter: string | null): void {
      listDto.filter = filter;
    },
  },
});
