import { LoginReturnCode } from "@/enums/LoginReturnCode";
import { ParticipationResult } from "@/enums/ParticipationResult";
import { ReturnCode } from "@/enums/ReturnCode";
import { SortDirection } from "@/enums/SortDirection";
import { StatusCode } from "@/enums/StatusCode";

export class DropdownOption<T> {
  public value: T;
  public text: string;

  constructor(value: T, text: string) {
    this.value = value;
    this.text = text;
  }
}

/**
 * Used for helping with enum keys and values.
 */
export const EnumHelper = {
  /* -------------------------------------------
  Gets a StatusCode enum value by string key.
  ------------------------------------------- */
  getStatusCode(enumKey: any): StatusCode {
    return StatusCode[enumKey as unknown as keyof typeof StatusCode];
  },

  /* -------------------------------------------
  Gets a SortDirection enum value by string key.
  ------------------------------------------- */
  getSortDirection(enumKey: any): SortDirection {
    return SortDirection[enumKey as unknown as keyof typeof SortDirection];
  },

  /* -------------------------------------------
  Gets a ReturnCode enum value by string key.
  ------------------------------------------- */
  getReturnCode(enumKey: any): ReturnCode {
    return ReturnCode[enumKey as unknown as keyof typeof ReturnCode];
  },

  /* -------------------------------------------
  Gets a LoginReturnCode enum value by string key.
  ------------------------------------------- */
  getLoginReturnCode(enumKey: any): LoginReturnCode {
    return LoginReturnCode[enumKey as unknown as keyof typeof LoginReturnCode];
  },

  /* -------------------------------------------
  Gets a ParticipationResult enum value by string key.
  ------------------------------------------- */
  getParticipationResult(enumKey: any): ParticipationResult {
    return ParticipationResult[enumKey as unknown as keyof typeof ParticipationResult];
  },

  /* -------------------------------------------
  Gets a LoginReturnCode enum value by string key.
  ------------------------------------------- */
  createDropdownOptionsFromEnum<T>(enumType: any): DropdownOption<T>[] {
    return Object.values(enumType)
      .filter((value) => typeof value === "number")
      .map((value) => value as T)
      .map((value) => new DropdownOption(value, enumType[value]));
  },
};
