<template>
  <template v-if="isFeedbackGivenSuccessfully">
    <h2>{{ Translator.translate("WEBFRONT_FEEDBACK_TITLE") }}</h2>
    <p>{{ Translator.translate("WEBFRONT_FEEDBACK_DESCRIPTION") }}</p>
  </template>
  <template v-else>
    <h2>{{ Translator.translate("WEBFRONT_RESULT_LOST_TITLE") }}</h2>
    <p>{{ Translator.translate("WEBFRONT_RESULT_LOST_DESCRIPTION") }}</p>

    <form @submit.prevent="" style="margin-top: 50px">
      <p class="m-0">{{ Translator.translate("WEBFRONT_FEEDBACK_INPUT_PROMPT1") }}</p>

      <div class="form-row">
        <span class="p-float-label">
          <InputText type="text" id="response1" :disabled="feedbackDto.isLoading" v-model="feedbackForm.response1" />
          <label for="response1">{{ Translator.translate("WEBFRONT_FEEDBACK_INPUT_NAME1") }}</label>
        </span>
        <small class="p-error" v-show="feedbackValidator.hasValidationErrors(feedbackDto, 'response1')">{{ Translator.translate("WEBFRONT_FEEDBACK_INPUT_VALIDATION") }}</small>
      </div>

      <p class="m-0">{{ Translator.translate("WEBFRONT_FEEDBACK_INPUT_PROMPT2") }}</p>

      <div class="form-row">
        <span class="p-float-label">
          <InputText type="text" id="response2" :disabled="feedbackDto.isLoading" v-model="feedbackForm.response2" />
          <label for="response2">{{ Translator.translate("WEBFRONT_FEEDBACK_INPUT_NAME2") }}</label>
        </span>
        <small class="p-error" v-show="feedbackValidator.hasValidationErrors(feedbackDto, 'response2')">{{ Translator.translate("WEBFRONT_FEEDBACK_INPUT_VALIDATION") }}</small>
      </div>

      <Message v-if="feedbackDto.hasError" severity="error" :closable="false">{{ Translator.translate("WEBFRONT_FEEDBACK_SUBMIT_ERROR") }}</Message>

      <Button :label="Translator.translate('WEBFRONT_SUBMIT_BUTTON')" @click="submitFeedback()" :loading="feedbackDto.isLoading"></Button>
    </form>
  </template>
</template>

<script lang="ts">
import { FeedbackDto } from "@/dtos/FeedbackDtos";
import { FeedbackFormData } from "@/dtos/data/FeedbackFormData";
import { FormHelper } from "@/helpers/FormHelper";
import { ObjectHelper } from "@/helpers/ObjectHelper";
import { Translator } from "@/helpers/Translator";
import { Validator } from "@/helpers/Validator";
import { FeedbackModel } from "@/models/FeedbackModel";
import { IFeedbackModel } from "@/models/interfaces/IFeedbackModel";
import { useFeedbackStore } from "@/stores/feedbackStore";
import { FeedbackViewModel } from "@/viewModels/ViewModels";
import { AxiosResponse } from "axios";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "WebFrontResultLost",
  components: {},
  methods: {
    submitFeedback() {
      const feedbackStore = useFeedbackStore();

      this.feedbackForm.shouldValidate = true;

      // update values in store.
      ObjectHelper.copyExistingPropsFromTo(this.feedbackForm, this.feedbackDto.model);

      // update values in store.
      this.feedbackValidator.validateForm(this.feedbackForm as FeedbackFormData, this.feedbackDto).then(() => {
        feedbackStore
          .createFeedback(this.feedbackDto)
          .then(() => {
            this.isFeedbackGivenSuccessfully = true;
          });
      });
    },
  },
  setup() {
    const feedbackValidator = new Validator<IFeedbackModel>();
    const formHelper = new FormHelper("/");

    const feedbackDto = ref(new FeedbackDto(new FeedbackModel()));
    const feedbackForm = ref(new FeedbackFormData(feedbackDto.value));

    const isFeedbackGivenSuccessfully = ref(false);

    return {
      feedbackValidator,
      formHelper,
      feedbackDto,
      feedbackForm,
      isFeedbackGivenSuccessfully,
      Translator,
    };
  },
});
</script>

<style scoped lang="scss"></style>
