/**
 * Helper methods related to string operations.
 */
export default {
  /* -------------------------------------------
  Capitalized the first letter of a string, and returns the rest in lower case.
  ------------------------------------------- */
  capitalizeFirstLetter(text: string): string {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  },

  /* -------------------------------------------
  Forcefully parses a string to a number.
  ------------------------------------------- */
  parseToNumber(input: string): number {
    return Number.parseInt(input, 10) || 0;
  },

  /* -------------------------------------------
  Strips "model." from any string.
  ------------------------------------------- */
  stripModelString(input: string): string {
    return input.replaceAll("model.", "");
  },

  /* -------------------------------------------
  Renders a date in swiss format.
  ------------------------------------------- */
  renderDate(input: Date, withTime: boolean = false): string {
    const padZero = (i: string | number): string => ("0" + i).slice(-2);

    const prettyDate = `${padZero(input.getDate())}.${padZero(input.getMonth() + 1)}.${input.getFullYear()}${withTime ? ", " + padZero(input.getHours()) + ":" + padZero(input.getMinutes()) : ""}`;
    return prettyDate;
  },
};
