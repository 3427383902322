<template>
  <div v-if="form.isReady()">
    <form @submit.prevent="">
      <Panel header="System-Informationen">
        <div class="form-row split">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-calendar"></i>
            <Calendar id="createdAt" :disabled="true" date-format="dd.mm.yy" showTime hourFormat="24" v-model="dto.model.createdAt" />
            <label for="createdAt">Erstellt am</label>
          </span>
          <div></div>
        </div>
      </Panel>
      <Panel header="Feedback">
        <div class="form-row split">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-comments"></i>
            <InputText type="text" id="response1" :disabled="true" v-model="form.response1" />
            <label for="response1">Antwort 1</label>
          </span>
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-comments"></i>
            <InputText type="text" id="response2" :disabled="true" v-model="form.response2" />
            <label for="response2">Antwort 2</label>
          </span>
        </div>
      </Panel>
    </form>

    <div class="button-panel">
      <div></div>
      <div>
        <Button type="button" label="Abbrechen" :severity="'secondary'" @click="formHelper.returnToList()"></Button>
      </div>
    </div>
  </div>

  <div v-else-if="dto.isLoading">
    <LoadingSkeleton></LoadingSkeleton>
  </div>

  <div v-else>
    <Message severity="warn" :closable="false">Es existiert keine Entität mit Id '{{ id }}'.</Message>
    <div>
      <Button type="button" label="Zurück zur Liste" :severity="'secondary'" @click="formHelper.returnToList()"></Button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, toRefs, watch } from "vue";
import { FeedbackFormData } from "@/dtos/data/FeedbackFormData";
import { FeedbackDto } from "@/dtos/FeedbackDtos";
import { FormHelper } from "@/helpers/FormHelper";
import { Validator } from "@/helpers/Validator";
import { IFeedbackModel } from "@/models/interfaces/IFeedbackModel";
import LoadingSkeleton from "@/components/shared/LoadingSkeleton.vue";

/**
 * A shared component used for editing (and creating) feedbacks.
 */
export default defineComponent({
  name: "FeedbacksCreateEdit",
  props: {
    id: Number,
    dto: {
      type: FeedbackDto,
      required: true,
    },
  },
  components: { LoadingSkeleton },
  methods: {},
  setup(props) {
    const validator = new Validator<IFeedbackModel>();
    const formHelper = new FormHelper("/controlpanel/feedbacks");

    // get entity from props.
    const { id, dto } = toRefs(props);

    // set up as form state as reactive object.
    const form = ref(new FeedbackFormData(dto.value));

    // setup form watchers.
    watch(dto.value, (newValue) => (form.value = new FeedbackFormData(newValue)));

    return {
      id,
      dto,
      form,
      formHelper,
      validator,
    };
  },
});
</script>

<style scoped lang="scss">
form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 40px;
  column-gap: 40px;

  .p-panel {
    width: 100%;
  }
}
:deep(.p-panel-content) {
  padding-top: 30px;

  // overwrite some img hover preview stylings.
  .p-image {
    width: fit-content !important;

    img {
      width: 100%;
      max-width: 800px;
      max-height: 800px;
    }
  }
  .p-image-preview-indicator {
    margin: 0 !important;
  }
}
</style>
