import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3ed08386"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-row" }
const _hoisted_2 = { class: "p-float-label" }
const _hoisted_3 = { for: "winCode" }
const _hoisted_4 = { class: "m-0" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "m-0" }
const _hoisted_7 = { class: "form-row" }
const _hoisted_8 = { class: "p-float-label" }
const _hoisted_9 = { for: "firstname" }
const _hoisted_10 = { class: "form-row" }
const _hoisted_11 = { class: "p-float-label" }
const _hoisted_12 = { for: "surname" }
const _hoisted_13 = { class: "form-row" }
const _hoisted_14 = { class: "p-float-label" }
const _hoisted_15 = { for: "street" }
const _hoisted_16 = { class: "form-row" }
const _hoisted_17 = { class: "p-float-label" }
const _hoisted_18 = { for: "streetNumber" }
const _hoisted_19 = { class: "form-row" }
const _hoisted_20 = { class: "p-float-label" }
const _hoisted_21 = { for: "zipCode" }
const _hoisted_22 = { class: "form-row" }
const _hoisted_23 = { class: "p-float-label" }
const _hoisted_24 = { for: "city" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Image = _resolveComponent("Image")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Message = _resolveComponent("Message")!
  const _component_CameraPopup = _resolveComponent("CameraPopup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("p", null, _toDisplayString(_ctx.Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_INPUT_PROMT1")), 1),
    _createElementVNode("form", {
      onSubmit: _cache[10] || (_cache[10] = _withModifiers(() => {}, ["prevent"])),
      onChange: _cache[11] || (_cache[11] = ($event: any) => (_ctx.validator.validateForm(_ctx.form as ParticipationWinnerFormData, _ctx.dto)))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, [
          _createVNode(_component_InputText, {
            type: "text",
            id: "winCode",
            disabled: _ctx.dto.isLoading,
            modelValue: _ctx.form.winCode,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.winCode) = $event)),
            class: _normalizeClass({ 'p-invalid': _ctx.validator.hasValidationErrors(_ctx.dto, 'phowinCodeneNumber') })
          }, null, 8, ["disabled", "modelValue", "class"]),
          _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_INPUT_WINCODE")), 1)
        ]),
        _withDirectives(_createElementVNode("small", { class: "p-error" }, _toDisplayString(_ctx.Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_INPUT_WINCODE_VALIDATION")), 513), [
          [_vShow, _ctx.validator.hasValidationErrors(_ctx.dto, 'winCode')]
        ])
      ]),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_INPUT_PROMT2")), 1),
      _createElementVNode("div", null, [
        _createVNode(_component_Button, {
          class: "icon rounded",
          icon: _ctx.form.receiptImage ? 'pi pi-check' : 'pi pi-camera',
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openCameraPopup()))
        }, null, 8, ["icon"]),
        _createVNode(_component_Button, {
          class: "icon rounded small",
          icon: "pi pi-file-import",
          onClick: _ctx.openImageFileDialog
        }, null, 8, ["onClick"]),
        _createElementVNode("input", {
          ref: "receiptImageRef",
          id: "receiptImage",
          type: "file",
          accept: "image/jpeg,image/png,application/pdf",
          onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onImageUploaded && _ctx.onImageUploaded(...args)))
        }, null, 544)
      ]),
      (_ctx.isPdfUploaded)
        ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.pdfFileName), 1))
        : _withDirectives((_openBlock(), _createBlock(_component_Image, {
            key: 1,
            src: _ctx.form.receiptImage
          }, null, 8, ["src"])), [
            [_vShow, _ctx.form.receiptImage]
          ]),
      _withDirectives(_createElementVNode("small", { class: "p-error" }, _toDisplayString(_ctx.Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_INPUT_RECEIPT_IMAGE_VALIDATION")), 513), [
        [_vShow, _ctx.validator.hasValidationErrors(_ctx.dto, 'receiptImage')]
      ]),
      _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_INPUT_PROMT3")), 1),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("span", _hoisted_8, [
          _createVNode(_component_InputText, {
            type: "text",
            id: "firstname",
            disabled: _ctx.dto.isLoading,
            modelValue: _ctx.form.firstname,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.firstname) = $event)),
            class: _normalizeClass({ 'p-invalid': _ctx.validator.hasValidationErrors(_ctx.dto, 'firstname') })
          }, null, 8, ["disabled", "modelValue", "class"]),
          _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_INPUT_FIRSTNAME")), 1)
        ]),
        _withDirectives(_createElementVNode("small", { class: "p-error" }, _toDisplayString(_ctx.Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_INPUT_FIRSTNAME_VALIDATION")), 513), [
          [_vShow, _ctx.validator.hasValidationErrors(_ctx.dto, 'firstname')]
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("span", _hoisted_11, [
          _createVNode(_component_InputText, {
            type: "text",
            id: "surname",
            disabled: _ctx.dto.isLoading,
            modelValue: _ctx.form.surname,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.surname) = $event)),
            class: _normalizeClass({ 'p-invalid': _ctx.validator.hasValidationErrors(_ctx.dto, 'surname') })
          }, null, 8, ["disabled", "modelValue", "class"]),
          _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_INPUT_SURNAME")), 1)
        ]),
        _withDirectives(_createElementVNode("small", { class: "p-error" }, _toDisplayString(_ctx.Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_INPUT_SURNAME_VALIDATION")), 513), [
          [_vShow, _ctx.validator.hasValidationErrors(_ctx.dto, 'surname')]
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("span", _hoisted_14, [
          _createVNode(_component_InputText, {
            type: "text",
            id: "street",
            disabled: _ctx.dto.isLoading,
            modelValue: _ctx.form.street,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.street) = $event)),
            class: _normalizeClass({ 'p-invalid': _ctx.validator.hasValidationErrors(_ctx.dto, 'street') })
          }, null, 8, ["disabled", "modelValue", "class"]),
          _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_INPUT_STREET")), 1)
        ]),
        _withDirectives(_createElementVNode("small", { class: "p-error" }, _toDisplayString(_ctx.Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_INPUT_STREET_VALIDATION")), 513), [
          [_vShow, _ctx.validator.hasValidationErrors(_ctx.dto, 'street')]
        ])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("span", _hoisted_17, [
          _createVNode(_component_InputText, {
            type: "text",
            id: "streetNumber",
            disabled: _ctx.dto.isLoading,
            modelValue: _ctx.form.streetNumber,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.streetNumber) = $event))
          }, null, 8, ["disabled", "modelValue"]),
          _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_INPUT_STREETNUMBER")), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("span", _hoisted_20, [
          _createVNode(_component_InputNumber, {
            id: "zipCode",
            disabled: _ctx.dto.isLoading,
            modelValue: _ctx.form.zipCode,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.zipCode) = $event)),
            min: 1,
            max: 9999,
            "use-grouping": false,
            class: _normalizeClass({ 'p-invalid': _ctx.validator.hasValidationErrors(_ctx.dto, 'zipCode') })
          }, null, 8, ["disabled", "modelValue", "class"]),
          _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_INPUT_ZIPCODE")), 1)
        ]),
        _withDirectives(_createElementVNode("small", { class: "p-error" }, _toDisplayString(_ctx.Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_INPUT_ZIPCODE_VALIDATION")), 513), [
          [_vShow, _ctx.validator.hasValidationErrors(_ctx.dto, 'zipCode')]
        ])
      ]),
      _createElementVNode("div", _hoisted_22, [
        _createElementVNode("span", _hoisted_23, [
          _createVNode(_component_InputText, {
            type: "text",
            id: "city",
            disabled: _ctx.dto.isLoading,
            modelValue: _ctx.form.city,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.city) = $event)),
            class: _normalizeClass({ 'p-invalid': _ctx.validator.hasValidationErrors(_ctx.dto, 'city') })
          }, null, 8, ["disabled", "modelValue", "class"]),
          _createElementVNode("label", _hoisted_24, _toDisplayString(_ctx.Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_INPUT_CITY")), 1)
        ]),
        _withDirectives(_createElementVNode("small", { class: "p-error" }, _toDisplayString(_ctx.Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_INPUT_CITY_VALIDATION")), 513), [
          [_vShow, _ctx.validator.hasValidationErrors(_ctx.dto, 'city')]
        ])
      ]),
      (_ctx.dto.hasError)
        ? (_openBlock(), _createBlock(_component_Message, {
            key: 2,
            severity: "error",
            closable: false
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.customError || _ctx.Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_ERROR")), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_Button, {
        label: _ctx.Translator.translate('WEBFRONT_SUBMIT_BUTTON'),
        onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.submitWinner())),
        loading: _ctx.dto.isLoading
      }, null, 8, ["label", "loading"])
    ], 32),
    _createVNode(_component_CameraPopup, {
      isOpen: _ctx.isCameraPopupOpen,
      onOnSnapshot: _ctx.onCameraSnapshot,
      onClosed: _ctx.closeCameraPopup
    }, null, 8, ["isOpen", "onOnSnapshot", "onClosed"])
  ], 64))
}