import { TypeMapping } from "@/dtos/shared/TypeMapping";
import { BaseModel } from "./BaseModel";
import { IParticipationModel as IParticipationModel } from "./interfaces/IParticipationModel";
import { ParticipationStatus } from "@/enums/ParticipationStatus";
import { TypeHelper } from "@/helpers/TypeHelper";

export class ParticipationModel extends BaseModel implements IParticipationModel {
  public phoneNumber: string;
  public participationStatus: ParticipationStatus;
  public winCode: string;
  public wonDate?: Date | null;
  public firstname: string;
  public surname: string;
  public street: string;
  public streetNumber?: string | null;
  public zipCode: number | null;
  public city: string;
  public receiptImage: string;
  public purchaseValue: number;

  constructor() {
    super();

    this.phoneNumber = "";
    this.participationStatus = ParticipationStatus.NONE;
    this.wonDate = null;
    this.winCode = "";
    this.firstname = "";
    this.surname = "";
    this.street = "";
    this.streetNumber = null;
    this.city = "";
    this.zipCode = null;
    this.receiptImage = "";
    this.purchaseValue = 0;
  }

  public static readonly mappings: TypeMapping[] = [
    {
      key: "wonDate",
      cast: (x) => TypeHelper.mappers.Date(x),
    },
    {
      key: "purchaseValue",
      cast: (x) => TypeHelper.mappers.Number(x),
    },
  ];
}
