import { ModelDto } from "./base/ModelDto";
import { TypeMapping } from "./shared/TypeMapping";
import { TypeHelper } from "@/helpers/TypeHelper";
import { ListDto } from "./base/ListDto";
import { FeedbackModel } from "@/models/FeedbackModel";

class FeedbackDto extends ModelDto<FeedbackModel> {
  public static readonly mappings: TypeMapping[] = [
    {
      key: "model",
      cast: (x) => TypeHelper.mappers.FeedbackModel(x),
    },
  ];
}

class FeedbackListDto extends ListDto<FeedbackDto> {
  public static readonly mappings: TypeMapping[] = [
    {
      key: "list",
      cast: (x) => x.map((y: any) => TypeHelper.mappers.FeedbackDto(y)),
    },
  ];
}

export { FeedbackDto, FeedbackListDto };
