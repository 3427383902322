<template>
  <router-view />
  <DebugDisplay></DebugDisplay>
</template>

<script lang="ts">
import { defineComponent, watch } from "vue";
import DebugDisplay from "@/components/shared/DebugDisplay.vue";
import { useTranslationStore } from "@/stores/translationStore";
import { usePrimeVue } from "primevue/config";
import { Language } from "./enums/Language";

export default defineComponent({
  name: "App",
  components: {
    DebugDisplay,
  },
  setup() {
    const primevue = usePrimeVue();
    const translationStore = useTranslationStore();

    const changeToGerman = () => {
      // change system language. This is only used for calendars.
      if (primevue.config.locale !== undefined) {
        primevue.config.locale.dayNames = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"];
        primevue.config.locale.dayNamesMin = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];
        primevue.config.locale.monthNames = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];
        primevue.config.locale.monthNamesShort = ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"];
      }
    };
    const changeToFrench = () => {
      // change system language. This is only used for calendars.
      if (primevue.config.locale !== undefined) {
        primevue.config.locale.dayNames = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
        primevue.config.locale.dayNamesMin = ["dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam."];
        primevue.config.locale.monthNames = ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"];
        primevue.config.locale.monthNamesShort = ["jan.", "fév.", "mars", "avr.", "mai", "juin", "juil.", "août", "sept.", "oct.", "nov.", "déc."];
      }
    };

    const setSystemLanguage = () => {
      // select language for system translations.
      switch (translationStore.currentLanguage) {
        case Language.FR:
          changeToFrench();
          break;
        default:
        case Language.DE:
          changeToGerman();
          break;
      }
    };

    // set initial language.
    setSystemLanguage();

    // create watch for current language.,
    watch(() => translationStore.currentLanguage, setSystemLanguage);

    return {
      setSystemLanguage,
    };
  },
});
</script>

<style lang="scss">
iframe#webpack-dev-server-client-overlay {
  display: none;
}
</style>
