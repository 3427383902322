export enum LogEntryType {
  NONE = 0,
  COMPETITION = 1,
  MODEL_CREATED = 100,
  MODEL_UPDATED = 101,
  MODEL_DELETED = 102,
  USER_INVALID_LOGIN_ATTEMPT = 200,
  MAIL_SUCCESSFULLY_SENT = 800,
  MAIL_COULD_NOT_BE_SENT = 801,
  JOB_EXECUTION = 802,
  SMS_SUCCESSFULLY_SENT = 810,
  SMS_COULD_NOT_BE_SENT = 811,
  SECURITY_WRONG_ORIGIN_COUNTRY = 900,
  SECURITY_REQUEST_LIMIT_REACHED = 901,
}
