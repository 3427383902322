<template>
  <h2>{{ Translator.translate("WEBFRONT_RESULT_WON_TITLE") }}</h2>
  <p>{{ Translator.translate("WEBFRONT_RESULT_WON_DESCRIPTION") }}</p>
</template>

<script lang="ts">
import { Translator } from "@/helpers/Translator";
import { defineComponent } from "vue";

export default defineComponent({
  name: "WebFrontResultWon",
  components: {},
  methods: {},
  setup() {
    return {
      Translator,
    };
  },
});
</script>

<style scoped lang="scss"></style>
