import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3765bca2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WebFrontHeader = _resolveComponent("WebFrontHeader")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_WebFrontHeader),
    (!_ctx.isTranslationsRestored)
      ? (_openBlock(), _createBlock(_component_ProgressSpinner, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_router_view)
        ]))
  ], 64))
}