import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, normalizeClass as _normalizeClass, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-33682a3e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "m-0" }
const _hoisted_2 = { class: "form-row" }
const _hoisted_3 = { class: "p-float-label" }
const _hoisted_4 = { for: "phoneNumber" }
const _hoisted_5 = { class: "mt-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Message = _resolveComponent("Message")!
  const _component_Translate = _resolveComponent("Translate")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.Translator.translate("WEBFRONT_LANDINGPAGE_TITLE")), 1),
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.Translator.translate("WEBFRONT_LANDINGPAGE_RUNNINGTEXT1")), 1),
    _createElementVNode("p", null, _toDisplayString(_ctx.Translator.translate("WEBFRONT_LANDINGPAGE_RUNNINGTEXT2")), 1),
    _createElementVNode("form", {
      onSubmit: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"])),
      onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.validator.validateForm(_ctx.form as ParticipationEntryFormData, _ctx.dto)))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, [
          _createVNode(_component_InputText, {
            type: "tel",
            id: "phoneNumber",
            onKeyup: _cache[0] || (_cache[0] = _withKeys(($event: any) => (_ctx.participate()), ["enter"])),
            disabled: _ctx.dto.isLoading,
            modelValue: _ctx.form.phoneNumber,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.phoneNumber) = $event)),
            class: _normalizeClass({ 'p-invalid': _ctx.validator.hasValidationErrors(_ctx.dto, 'phoneNumber') })
          }, null, 8, ["disabled", "modelValue", "class"]),
          _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.Translator.translate("WEBFRONT_LANDINGPAGE_INPUT_PHONENUMBER")), 1)
        ]),
        _withDirectives(_createElementVNode("small", { class: "p-error" }, _toDisplayString(_ctx.Translator.translate("WEBFRONT_LANDINGPAGE_INPUT_PHONENUMBER_VALIDATION")), 513), [
          [_vShow, _ctx.validator.hasValidationErrors(_ctx.dto, 'phoneNumber')]
        ])
      ]),
      _createVNode(_component_Button, {
        label: _ctx.Translator.translate('WEBFRONT_SUBMIT_BUTTON'),
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.participate())),
        loading: _ctx.dto.isLoading
      }, null, 8, ["label", "loading"])
    ], 32),
    (_ctx.dto.hasError)
      ? (_openBlock(), _createBlock(_component_Message, {
          key: 0,
          severity: "error",
          closable: false
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.Translator.translate("WEBFRONT_LANDINGPAGE_ERROR")), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createElementVNode("p", _hoisted_5, [
      _createVNode(_component_Translate, { text: "WEBFRONT_LANDINGPAGE_ADDITIONAL_INFO1" })
    ]),
    _createElementVNode("p", null, [
      _createVNode(_component_Translate, { text: "WEBFRONT_LANDINGPAGE_ADDITIONAL_INFO2" })
    ]),
    _createElementVNode("p", null, [
      _createVNode(_component_router_link, { to: "/terms-and-conditions" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.Translator.translate("WEBFRONT_LANDINGPAGE_TERMS_AND_CONDITIONS")), 1)
        ]),
        _: 1
      })
    ])
  ], 64))
}