import { BaseDto } from "@/dtos/base/BaseDto";
import { BaseFormData } from "@/dtos/data/BaseFormData";
import * as Yup from "yup";

/**
 * Helper class to help with HTML form validation.
 */
export class Validator<T extends Yup.AnyObject> {
  constructor() {}

  /* -------------------------------------------
  Validates the given form. Does not reject, but fills the validationErrors field instead.
  ------------------------------------------- */
  public async validateForm(form: BaseFormData<T>, dto: BaseDto): Promise<void> {
    if (!form.shouldValidate) return;

    // clear previous validation errors.
    dto.validationErrorFields.splice(0);

    // validate.
    return new Promise<void>((resolve, reject) => {
      form.formSchema
        .validate(form, { abortEarly: false })
        .then(() => {
          resolve();
        })
        .catch((e: Yup.ValidationError) => {
          e.inner.forEach((error: Yup.ValidationError) => {
            if (error.path !== undefined) {
              dto.validationErrorFields.push(error.path);
            }
          });
          reject(`There are form validation errors: \r\n${dto.validationErrorFields.join("\r\n")}`);
        });
    });
  }

  /* -------------------------------------------
  Returns true if there are validationErrors present.
  ------------------------------------------- */
  public hasValidationErrors(dto: BaseDto, fieldName: string) {
    return dto.validationErrorFields.some((f) => f === fieldName);
  }
}
