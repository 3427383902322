<template>
  <p>{{ Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_INPUT_PROMT1") }}</p>

  <form @submit.prevent="" @change="validator.validateForm(form as ParticipationWinnerFormData, dto)">
    <div class="form-row">
      <span class="p-float-label">
        <InputText type="text" id="winCode" :disabled="dto.isLoading" v-model="form.winCode" :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'phowinCodeneNumber') }" />
        <label for="winCode">{{ Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_INPUT_WINCODE") }}</label>
      </span>
      <small class="p-error" v-show="validator.hasValidationErrors(dto, 'winCode')">{{ Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_INPUT_WINCODE_VALIDATION") }}</small>
    </div>

    <p class="m-0">{{ Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_INPUT_PROMT2") }}</p>

    <div>
      <Button class="icon rounded" :icon="form.receiptImage ? 'pi pi-check' : 'pi pi-camera'" @click="openCameraPopup()"></Button>
      <Button class="icon rounded small" icon="pi pi-file-import" @click="openImageFileDialog"></Button>
      <input ref="receiptImageRef" id="receiptImage" type="file" accept="image/jpeg,image/png,application/pdf" @change="onImageUploaded" />
    </div>

    <p v-if="isPdfUploaded">{{ pdfFileName }}</p>
    <Image v-else :src="form.receiptImage" v-show="form.receiptImage"></Image>
    <small class="p-error" v-show="validator.hasValidationErrors(dto, 'receiptImage')">{{ Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_INPUT_RECEIPT_IMAGE_VALIDATION") }}</small>

    <p class="m-0">{{ Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_INPUT_PROMT3") }}</p>

    <div class="form-row">
      <span class="p-float-label">
        <InputText type="text" id="firstname" :disabled="dto.isLoading" v-model="form.firstname" :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'firstname') }" />
        <label for="firstname">{{ Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_INPUT_FIRSTNAME") }}</label>
      </span>
      <small class="p-error" v-show="validator.hasValidationErrors(dto, 'firstname')">{{ Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_INPUT_FIRSTNAME_VALIDATION") }}</small>
    </div>

    <div class="form-row">
      <span class="p-float-label">
        <InputText type="text" id="surname" :disabled="dto.isLoading" v-model="form.surname" :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'surname') }" />
        <label for="surname">{{ Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_INPUT_SURNAME") }}</label>
      </span>
      <small class="p-error" v-show="validator.hasValidationErrors(dto, 'surname')">{{ Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_INPUT_SURNAME_VALIDATION") }}</small>
    </div>

    <div class="form-row">
      <span class="p-float-label">
        <InputText type="text" id="street" :disabled="dto.isLoading" v-model="form.street" :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'street') }" />
        <label for="street">{{ Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_INPUT_STREET") }}</label>
      </span>
      <small class="p-error" v-show="validator.hasValidationErrors(dto, 'street')">{{ Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_INPUT_STREET_VALIDATION") }}</small>
    </div>

    <div class="form-row">
      <span class="p-float-label">
        <InputText type="text" id="streetNumber" :disabled="dto.isLoading" v-model="form.streetNumber" />
        <label for="streetNumber">{{ Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_INPUT_STREETNUMBER") }}</label>
      </span>
    </div>

    <div class="form-row">
      <span class="p-float-label">
        <InputNumber id="zipCode" :disabled="dto.isLoading" v-model="form.zipCode" :min="1" :max="9999" :use-grouping="false" :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'zipCode') }" />
        <label for="zipCode">{{ Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_INPUT_ZIPCODE") }}</label>
      </span>
      <small class="p-error" v-show="validator.hasValidationErrors(dto, 'zipCode')">{{ Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_INPUT_ZIPCODE_VALIDATION") }}</small>
    </div>

    <div class="form-row">
      <span class="p-float-label">
        <InputText type="text" id="city" :disabled="dto.isLoading" v-model="form.city" :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'city') }" />
        <label for="city">{{ Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_INPUT_CITY") }}</label>
      </span>
      <small class="p-error" v-show="validator.hasValidationErrors(dto, 'city')">{{ Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_INPUT_CITY_VALIDATION") }}</small>
    </div>

    <Message v-if="dto.hasError" severity="error" :closable="false">{{ customError || Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_ERROR") }}</Message>

    <Button :label="Translator.translate('WEBFRONT_SUBMIT_BUTTON')" @click="submitWinner()" :loading="dto.isLoading"></Button>
  </form>

  <CameraPopup :isOpen="isCameraPopupOpen" @on-snapshot="onCameraSnapshot" @closed="closeCameraPopup"></CameraPopup>
</template>

<script lang="ts">
import { ParticipationDto } from "@/dtos/ParticipationDtos";
import { ParticipationWinnerFormData } from "@/dtos/data/ParticipationWinnerFormData";
import { StatusCode } from "@/enums/StatusCode";
import { FormHelper } from "@/helpers/FormHelper";
import { ObjectHelper } from "@/helpers/ObjectHelper";
import { Translator } from "@/helpers/Translator";
import { Validator } from "@/helpers/Validator";
import { ParticipationModel } from "@/models/ParticipationModel";
import { IParticipationModel } from "@/models/interfaces/IParticipationModel";
import { useParticipationStore } from "@/stores/participationStore";
import { ParticipationViewModel } from "@/viewModels/ViewModels";
import { AxiosResponse } from "axios";
import { defineComponent, ref } from "vue";
import CameraPopup from "@/components/webfront/CameraPopup.vue";

export default defineComponent({
  name: "WebFrontResultWonConfirmed",
  components: {
    CameraPopup,
  },
  methods: {
    submitWinner() {
      const participationStore = useParticipationStore();

      this.form.shouldValidate = true;

      // update values in store.
      ObjectHelper.copyExistingPropsFromTo(this.form, this.dto.model);

      this.validator.validateForm(this.form as ParticipationWinnerFormData, this.dto).then(() => {
        participationStore
          .updateParticipation(this.dto as ParticipationDto)
          .then(() => {
            this.$router.push("result-won-feedback");
          })
          .catch((error: AxiosResponse<ParticipationViewModel>) => {
            switch (error.data.statusCode) {
              case StatusCode.PARTICIPATION_WIN_CODE_NOT_FOUND:
                this.customError = Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_ERROR_WIN_CODE_NOT_FOUND");
                break;
              case StatusCode.PARTICIPATION_WIN_CODE_ALREADY_REDEEMED:
                this.customError = Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_ERROR_WIN_CODE_ALREADY_USED");
                break;
            }
          });
      });
    },

    openCameraPopup() {
      this.isCameraPopupOpen = true;
      document.body.style.overflow = "hidden";
    },

    closeCameraPopup() {
      this.isCameraPopupOpen = false;
      document.body.style.overflow = "";
    },

    onCameraSnapshot(base64Image: string) {
      this.form.receiptImage = base64Image;

      this.closeCameraPopup();
    },

    async openImageFileDialog() {
      this.receiptImageRef?.click();
    },

    async onImageUploaded(event: Event) {
      const MAX_FILE_SIZE = 5 /*MB*/ * 1024 /*KB*/ * 1024; /*B*/

      // get file from input.
      const files = (event.target as HTMLInputElement)?.files || [];
      const file = files[0];

      const isImage = file.type === "image/png" || file.type === "image/jpeg";
      const isPdf = file.type === "application/pdf";
      const isValidFileType = isImage || isPdf;

      if (!isValidFileType) {
        // check file type.
        this.dto.validationErrorFields.push("receiptImage");
      } else if (file.size > MAX_FILE_SIZE) {
        // check file size.
        this.dto.validationErrorFields.push("receiptImage");
      } else {
        // remove validation error because it might have been added if file was too large.
        var index = this.dto.validationErrorFields.indexOf("receiptImage");
        if (index > -1) {
          this.dto.validationErrorFields.splice(index, 1);
        }

        // parse to base64.
        const fileBase64 = await this.formHelper.blobImageToBase64(file);
        this.form.receiptImage = fileBase64;
        this.isPdfUploaded = isPdf;
        this.pdfFileName = isPdf ? file.name : "";

        // trigger validation.
        this.validator.validateForm(this.form as ParticipationWinnerFormData, this.dto);
      }
    },
  },
  setup() {
    const validator = new Validator<IParticipationModel>();
    const formHelper = new FormHelper("/");

    const dto = ref(new ParticipationDto(new ParticipationModel()));
    const form = ref(new ParticipationWinnerFormData(dto.value));

    const receiptImageRef = ref<InstanceType<typeof HTMLInputElement>>();
    const isCameraPopupOpen = ref(false);
    const isPdfUploaded = ref(false);
    const pdfFileName = ref("");

    const customError = ref("");
    const imageSource = ref();
    imageSource.value = require("@/assets/img/logo-250x250.png");


    return {
      validator,
      formHelper,
      dto,
      form,
      receiptImageRef,
      imageSource,
      customError,
      Translator,
      isCameraPopupOpen,
      isPdfUploaded,
      pdfFileName,
    };
  },
});
</script>

<style scoped lang="scss">
.invisible {
  display: none;
}

form {
  margin-top: 10px;

  button {
    &.p-button.rounded {
      padding: 5px 60px;

      :deep(.p-button-icon) {
        font-size: $font-size-large;
      }

      &.small {
        margin-left: 5px;
        padding: 5px 10px;
      }
    }
  }

  :deep(img) {
    width: 100%;
    aspect-ratio: 3 / 4;
    object-fit: contain;
    background: $sp-secondary-background-color;
  }
}

.p-message {
  width: 100%;
  max-width: 600px;
}

input[type="file"] {
  display: none;
}
</style>
