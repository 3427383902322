import * as Yup from "yup";
import { BaseFormData } from "./BaseFormData";
import { toRaw } from "vue";
import { IParticipationModel } from "@/models/interfaces/IParticipationModel";
import { ParticipationDto } from "../ParticipationDtos";
import { ParticipationModel } from "@/models/ParticipationModel";
import { ParticipationStatus } from "@/enums/ParticipationStatus";

export class ParticipationWinnerFormData extends BaseFormData<IParticipationModel> implements IParticipationModel {
  public winCode: string;
  public participationStatus: ParticipationStatus;
  public firstname: string;
  public surname: string;
  public street: string;
  public streetNumber?: string | null;
  public zipCode: number | null;
  public city: string;
  public receiptImage: string;
  public purchaseValue: number;

  public originalData: ParticipationModel;

  constructor(dto: ParticipationDto) {
    super(dto);

    if (dto.model.id) {
      this.isInitializedProperly = true;

      if (dto.model.id === -1) {
        this.isInitializedWithNewEntity = true;
      }
    }

    this.winCode = dto.model.winCode;
    this.participationStatus = dto.model.participationStatus;
    this.firstname = dto.model.firstname;
    this.surname = dto.model.surname;
    this.street = dto.model.street;
    this.streetNumber = dto.model.streetNumber;
    this.zipCode = dto.model.zipCode;
    this.city = dto.model.city;
    this.receiptImage = dto.model.receiptImage;
    this.purchaseValue = dto.model.purchaseValue;

    this.originalData = structuredClone(toRaw(dto.model));

    this.formSchema = Yup.object({
      winCode: Yup.string().required(),
      participationStatus: Yup.mixed<ParticipationStatus>().required(),
      firstname: Yup.string().required(),
      surname: Yup.string().required(),
      street: Yup.string().required(),
      streetNumber: Yup.string().optional().nullable(),
      zipCode: Yup.number().min(0).max(9999).required(),
      city: Yup.string().required(),
      receiptImage: Yup.string().required(),
      purchaseValue: Yup.number().required(),
    });
  }
}
