import * as Yup from "yup";
import { BaseFormData } from "./BaseFormData";
import { FeedbackData } from "./FeedbackData";
import { toRaw } from "vue";
import { FeedbackModel } from "@/models/FeedbackModel";
import { FeedbackDto } from "../FeedbackDtos";

export class FeedbackFormData extends BaseFormData<FeedbackData> {
  public response1: string;
  public response2: string;

  public originalData: FeedbackModel;

  constructor(dto: FeedbackDto) {
    super(dto);

    this.isInitializedProperly = true;

    this.response1 = dto.model.response1;
    this.response2 = dto.model.response2;

    this.originalData = structuredClone(toRaw(dto.model));

    this.formSchema = Yup.object({
      response1: Yup.string().required(),
      response2: Yup.string().required(),
    });
  }
}
