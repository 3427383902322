<template>
  <template v-if="isFeedbackGivenSuccessfully">
    <h2>{{ Translator.translate("WEBFRONT_FEEDBACK_TITLE") }}</h2>
    <p>{{ Translator.translate("WEBFRONT_FEEDBACK_DESCRIPTION") }}</p>
  </template>

  <template v-else>
    <h2>{{ Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_TITLE") }}</h2>
    <p>{{ Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_DESCRIPTION") }}</p>

    <form @submit.prevent="">
      <p>{{ Translator.translate("WEBFRONT_FEEDBACK_INPUT_PROMPT1") }}</p>

      <div class="form-row">
        <span class="p-float-label">
          <InputText type="text" id="response1" :disabled="dto.isLoading" v-model="form.response1" />
          <label for="response1">{{ Translator.translate("WEBFRONT_FEEDBACK_INPUT_NAME1") }}</label>
        </span>
        <small class="p-error" v-show="validator.hasValidationErrors(dto, 'response1')">{{ Translator.translate("WEBFRONT_FEEDBACK_INPUT_VALIDATION") }}</small>
      </div>

      <p>{{ Translator.translate("WEBFRONT_FEEDBACK_INPUT_PROMPT2") }}</p>

      <div class="form-row">
        <span class="p-float-label">
          <InputText type="text" id="response2" :disabled="dto.isLoading" v-model="form.response2" />
          <label for="response2">{{ Translator.translate("WEBFRONT_FEEDBACK_INPUT_NAME2") }}</label>
        </span>
        <small class="p-error" v-show="validator.hasValidationErrors(dto, 'response2')">{{ Translator.translate("WEBFRONT_FEEDBACK_INPUT_VALIDATION") }}</small>
      </div>

      <Message v-if="dto.hasError" severity="error" :closable="false">{{ Translator.translate("WEBFRONT_FEEDBACK_SUBMIT_ERROR") }}</Message>

      <Button :label="Translator.translate('WEBFRONT_SUBMIT_BUTTON')" @click="submitFeedback()" :loading="dto.isLoading"></Button>
    </form>
  </template>
</template>

<script lang="ts">
import { FeedbackDto } from "@/dtos/FeedbackDtos";
import { FeedbackFormData } from "@/dtos/data/FeedbackFormData";
import { FormHelper } from "@/helpers/FormHelper";
import { ObjectHelper } from "@/helpers/ObjectHelper";
import { Translator } from "@/helpers/Translator";
import { Validator } from "@/helpers/Validator";
import { FeedbackModel } from "@/models/FeedbackModel";
import { IFeedbackModel } from "@/models/interfaces/IFeedbackModel";
import { useFeedbackStore } from "@/stores/feedbackStore";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "WebFrontResultWonConfirmedFeedback",
  components: {},
  methods: {
    submitFeedback() {
      const feedbackStore = useFeedbackStore();

      this.form.shouldValidate = true;

      // update values in store.
      ObjectHelper.copyExistingPropsFromTo(this.form, this.dto.model);

      // update values in store.
      this.validator.validateForm(this.form as FeedbackFormData, this.dto).then(() => {
        feedbackStore.createFeedback(this.dto).then(() => {
          this.isFeedbackGivenSuccessfully = true;
        });
      });
    },
  },
  setup() {
    const validator = new Validator<IFeedbackModel>();
    const formHelper = new FormHelper("/");

    const dto = ref(new FeedbackDto(new FeedbackModel()));
    const form = ref(new FeedbackFormData(dto.value));

    const isFeedbackGivenSuccessfully = ref(false);

    return {
      validator,
      formHelper,
      dto,
      form,
      isFeedbackGivenSuccessfully,
      Translator,
    };
  },
});
</script>

<style scoped lang="scss">
.invisible {
  display: none;
}

form {
  margin-top: 10px;

  button {
    &.p-button.rounded {
      padding: 5px 60px;

      :deep(.p-button-icon) {
        font-size: $font-size-large;
      }
    }
  }

  :deep(img) {
    width: 100%;
    aspect-ratio: 3 / 4;
    object-fit: contain;
    background: $sp-secondary-background-color;
  }
}

.p-message {
  width: 100%;
  max-width: 600px;
}
</style>
