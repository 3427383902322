<template>
  <template v-if="isDetails">
    <Skeleton class="mb-2"></Skeleton>
    <Skeleton width="10rem" class="mb-2"></Skeleton>
    <Skeleton width="15rem" class="mb-2"></Skeleton>
    <Skeleton width="5rem" class="mb-2"></Skeleton>
    <Skeleton height="2rem" class="mb-2"></Skeleton>
    <Skeleton width="15rem" class="mb-2"></Skeleton>
    <Skeleton height="2rem" class="mb-2"></Skeleton>
    <Skeleton width="5rem" class="mb-2"></Skeleton>
  </template>
  <template v-else-if="isBlock">
    <Skeleton :width="width" :height="height"></Skeleton>
  </template>
  <template v-else>
    <Skeleton class="mb-2" :height="height"></Skeleton>
  </template>
</template>

<script lang="ts">
import { SkeletonType } from "@/enums/SkeletonType";
import { PropType, computed, defineComponent } from "vue";

/**
 * A slightly more customizable skeleton preset.
 */
export default defineComponent({
  name: "LoadingSkeleton",
  props: {
    skeletonType: {
      type: String as PropType<SkeletonType>,
    },
    width: String,
    height: String,
  },
  setup(props) {
    const isDetails = computed(() => props.skeletonType === SkeletonType.DETAILS);
    const isBlock = computed(() => props.skeletonType === SkeletonType.BLOCK);

    return {
      isDetails,
      isBlock,
    };
  },
});
</script>

<style scoped lang="scss"></style>
