export enum StatusCode {
  ////////////////////////////
  // Global (0)
  ////////////////////////////

  NONE = 0,
  OK = 1,
  ERROR = 2,
  MODEL_NOT_FOUND = 3,
  MODEL_UPDATE_FAILED = 4,
  MODEL_CREATE_FAILED = 5,
  MODEL_DELETE_FAILED = 6,
  POSITIVE_NUMBER_REQUIRED = 7,

  ////////////////////////////
  // User (100)
  ////////////////////////////

  USER_NOT_FOUND = 100,
  USER_OLD_PASSWORD_DOES_NOT_MATCH = 101,
  USER_NEW_PASSWORDS_DO_NOT_MATCH = 102,
  USER_NEW_PASSWORD_DOES_NOT_MEET_COMPLEXITY_RULES = 103,
  USER_IS_NOT_ACTIVE = 104,
  USER_PASSWORD_RESET_NOT_STARTED = 105,
  USER_PASSWORD_RESET_CODE_DOES_NOT_MATCH = 106,
  USER_NOT_LOGGED_IN = 107,
  USER_VERIFICATION_ALREADY_STARTED = 108,
  USER_VERIFICATION_NOT_STARTED = 109,
  USER_VERIFICATION_CODE_DOES_NOT_MATCH = 110,

  ////////////////////////////
  // Translations (300)
  ////////////////////////////

  TRANSLATION_NOT_FOUND = 300,

  ////////////////////////////
  // Participations (400)
  ////////////////////////////

  PARTICIPATION_NOT_FOUND = 400,
  PARTICIPATION_WIN_CODE_NOT_FOUND = 401,
  PARTICIPATION_PHONE_ALREADY_USED = 402,
  PARTICIPATION_WIN_CODE_ALREADY_REDEEMED = 403,
  
  ////////////////////////////
  // Feedbacks (500)
  ////////////////////////////

  FEEDBACK_NOT_FOUND = 500,
}
