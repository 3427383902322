<template>
  <h2>{{ Translator.translate("WEBFRONT_TERMS_AND_CONDITIONS_TITLE") }}</h2>
  <div>
    <Translate text="WEBFRONT_TERMS_AND_CONDITIONS_DESCRIPTION"></Translate>
  </div>
</template>

<script lang="ts">
import Translate from "@/components/shared/Translate.vue";
import { Translator } from "@/helpers/Translator";
import { defineComponent } from "vue";

export default defineComponent({
  name: "WebFrontTermsAndConditions",
  components: {
    Translate,
  },
  methods: {},
  setup() {
    return {
      Translator,
    };
  },
});
</script>

<style scoped lang="scss">
div {
  text-align: left;
  max-width: 800px;
}
</style>
