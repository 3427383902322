import { ReportingApiController } from "@/api/ReportingApiController";
import { ReportingViewModel } from "@/viewModels/ReportingViewModel";
import { AxiosResponse } from "axios";
import { defineStore } from "pinia";

const apiController = new ReportingApiController();

export const useReportingStore = defineStore("reporting", {
  state() {
    return { 
      isParticipationsLoading: false,
      isSecurityLoading: false,
      isSmsLoading: false,
     };
  },
  actions: {
    /* -------------------------------------------
     * Get all Participations Reportings from API.
     * -------------------------------------------- */
    async getParticipationsReport(): Promise<AxiosResponse<ReportingViewModel>> {
      this.isParticipationsLoading = true;

      return apiController.getParticipationsReport().finally(() => {
        this.isParticipationsLoading = false;
      });
    },

    /* -------------------------------------------
     * Get all Security Reportings from API.
     * -------------------------------------------- */
    async getSecurityReport(): Promise<AxiosResponse<ReportingViewModel>> {
      this.isSecurityLoading = true;

      return apiController.getSecurityReport().finally(() => {
        this.isSecurityLoading = false;
      });
    },

    /* -------------------------------------------
     * Get all SMS Reportings from API.
     * -------------------------------------------- */
    async getSmsReport(): Promise<AxiosResponse<ReportingViewModel>> {
      this.isSmsLoading = true;

      return apiController.getSmsReport().finally(() => {
        this.isSmsLoading = false;
      });
    },
  },
});
