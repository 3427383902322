import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a5ed1d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_camera = _resolveComponent("camera")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", {
    id: "popup",
    class: _normalizeClass({ 'is-open': _ctx.isCameraPopupOpen })
  }, [
    _createVNode(_component_camera, {
      ref: "cameraRef",
      constraints: _ctx.cameraConstraints,
      onStarted: _ctx.cameraLoadComplete,
      autoplay: false
    }, {
      default: _withCtx(() => [
        _withDirectives(_createVNode(_component_ProgressSpinner, null, null, 512), [
          [_vShow, _ctx.isCameraLoading]
        ]),
        _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.cameraError), 1)
      ]),
      _: 1
    }, 8, ["constraints", "onStarted"]),
    _createVNode(_component_Button, {
      class: "icon rounded cancel",
      icon: 'pi pi-times',
      onClick: _ctx.closeCameraPopup
    }, null, 8, ["onClick"]),
    _createVNode(_component_Button, {
      class: "icon rounded confirm",
      icon: 'pi pi-camera',
      onClick: _ctx.takeCameraSnapshot,
      loading: _ctx.isCameraLoading
    }, null, 8, ["onClick", "loading"])
  ], 2))
}