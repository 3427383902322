<template>
  <header>
    <div>
      <router-link to="/controlpanel" v-if="!isMobile"><img class="logo" src="@/assets/img/logo-250x250.png" alt="Logo" /></router-link>
      <h1 class="m-0">Control Panel</h1>
    </div>

    <div class="user-info">
      <div class="user">
        <span>{{ userDisplayName }}</span>
        <a @click="logout">Logout</a>
      </div>
      <i class="pi pi-user"></i>
    </div>
  </header>
</template>

<script lang="ts">
import { Constants } from "@/enums/Constants";
import stringMixins from "@/mixins/stringMixins";
import { useUserStatusStore } from "@/stores/userStatusStore";
import { useBreakpoints } from "@vueuse/core";
import { computed, defineComponent } from "vue";
import { useRouter } from "vue-router";

/**
 * A component to render the header in the control panel.
 * Shows information about the logged in user.
 */
export default defineComponent({
  name: "ControlPanelHeader",
  setup() {
    const userStatusStore = useUserStatusStore();
    const router = useRouter();

    const isAuthenticated = userStatusStore.isAuthenticated;
    const userDisplayName = computed(() => userStatusStore.userDto.model.displayName);
    const roleDisplayName = computed(() => stringMixins.capitalizeFirstLetter(userStatusStore.userDto.model.role.name));

    const breakpoints = useBreakpoints(Constants.Breakpoints);

    const isMobile = breakpoints.isSmallerOrEqual("mobile");

    const logout = () => {
      userStatusStore.postLogout().then(() => router.push("/"));
    };

    return {
      isAuthenticated,
      userDisplayName,
      roleDisplayName,
      isMobile,
      logout,
    };
  },
});
</script>

<style scoped lang="scss">
header {
  font-family: Arial, sans-serif;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  background: $sp-controlpanel-background-color;
  color: $sp-controlpanel-foreground-color;
  padding: 0 20px;
  z-index: 2;
  box-shadow: 0 0 10px black;

  h1 {
    font-weight: bold;
  }

  a {
    height: 70%;
    margin-right: 20px;

    img {
      height: 100%;
    }
  }

  div {
    display: flex;
    align-items: center;
  }

  .user-info {
    flex-direction: row;

    .user {
      flex-direction: column;
      align-items: end;
      padding-right: 10px;

      a {
        text-decoration: underline;
        margin: 0;
        cursor: pointer;
      }
    }

    i {
      font-size: 2rem;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  header {
    position: relative;
  }
}
</style>
