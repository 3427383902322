import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Translate = _resolveComponent("Translate")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h2", null, _toDisplayString(_ctx.Translator.translate("WEBFRONT_TERMS_AND_CONDITIONS_TITLE")), 1),
    _createElementVNode("div", null, [
      _createVNode(_component_Translate, { text: "WEBFRONT_TERMS_AND_CONDITIONS_DESCRIPTION" })
    ])
  ], 64))
}