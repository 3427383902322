<template>
  <div id="popup" :class="{ 'is-open': isCameraPopupOpen }">
    <camera ref="cameraRef" :constraints="cameraConstraints" @started="cameraLoadComplete" :autoplay="false">
      <ProgressSpinner v-show="isCameraLoading"></ProgressSpinner>
      <p class="error">{{ cameraError }}</p>
    </camera>
    <Button class="icon rounded cancel" :icon="'pi pi-times'" @click="closeCameraPopup"></Button>
    <Button class="icon rounded confirm" :icon="'pi pi-camera'" @click="takeCameraSnapshot" :loading="isCameraLoading"></Button>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, toRef, watch } from "vue";
import Camera from "simple-vue-camera";
import { FormHelper } from "@/helpers/FormHelper";
import { DeviceHelper } from "@/helpers/DeviceHelper";
import { MediaPermissionsError, MediaPermissionsErrorType, requestMediaPermissions } from "mic-check";
import { Resolution } from "simple-vue-camera/dist/components/resolution";

/**
 * Component used to render the navigation items in the Web Front.
 */
export default defineComponent({
  name: "WebFrontCameraPopup",
  components: {},
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const DEFAULT_SNAPSHOT_QUALITY = 1.0; /* Max quality */
    const DEFAULT_MOBILE_RESOLUTION = { width: 414, height: 896 } as Resolution; /* Most common mobile viewport */

    const formHelper = new FormHelper("/");

    const isCameraPopupOpen = toRef(props, "isOpen");
    const isCameraLoading = ref(true);
    const cameraRef = ref<InstanceType<typeof Camera>>();
    const cameraError = ref("");

    // for mobile devices, the aspect ratio is apparently different.
    const aspectRatio = DEFAULT_MOBILE_RESOLUTION.width / DEFAULT_MOBILE_RESOLUTION.height;
    const inverseAspectRatio = DEFAULT_MOBILE_RESOLUTION.height / DEFAULT_MOBILE_RESOLUTION.width;

    const cameraConstraints = {
      video: {
        aspectRatio: DeviceHelper.isMobile() ? inverseAspectRatio : aspectRatio,
        facingMode: "environment",
      },
      audio: false,
    } as MediaStreamConstraints;

    // Use camera reference to call functions
    const takeCameraSnapshot = async () => {
      if (!cameraRef.value) return;

      const blob = await cameraRef.value.snapshot(DEFAULT_MOBILE_RESOLUTION, "image/png", DEFAULT_SNAPSHOT_QUALITY);

      // To show the screenshot with an image tag, create a url
      if (blob) {
        const base64Data = await formHelper.blobImageToBase64(blob as File);

        // pause camera stream show loader again.
        isCameraLoading.value = true;
        cameraRef.value.pause();

        // take picture (fake delay for user experience).
        setTimeout(() => {
          emit("onSnapshot", base64Data);
        }, 500);
      }
    };

    const cameraLoadComplete = () => {
      isCameraLoading.value = false;
    };

    const closeCameraPopup = () => {
      emit("closed");
    };

    // dynamically start and stop camera.
    watch(isCameraPopupOpen, (isOpen: boolean) => {
      if (cameraRef.value) {
        if (isOpen) {
          // Get camera permission first.
          requestMediaPermissions(cameraConstraints)
            .then(() => {
              // access camera stream.
              cameraRef.value?.start();
            })
            .catch((err: MediaPermissionsError) => {
              const { type, name, message } = err;
              cameraError.value = `${type}: ${message}`;

              if (type === MediaPermissionsErrorType.SystemPermissionDenied) {
                // browser does not have permission to access camera or microphone
              } else if (type === MediaPermissionsErrorType.UserPermissionDenied) {
                // user didn't allow app to access camera or microphone
              } else if (type === MediaPermissionsErrorType.CouldNotStartVideoSource) {
                // camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)
                // (mostly Windows specific problem)
              } else {
                // not all error types are handled by this library
              }
            });
        } else {
          cameraRef.value.stop();
        }
      }
    });

    return {
      cameraConstraints,
      cameraRef,
      cameraError,
      isCameraPopupOpen,
      isCameraLoading,
      takeCameraSnapshot,
      cameraLoadComplete,
      closeCameraPopup,
    };
  },
});
</script>

<style scoped lang="scss">
#popup {
  position: fixed;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  transition: top 500ms ease;
  background: black;

  &.is-open {
    top: 0;
  }

  .p-progress-spinner {
    margin-top: 30vh;
  }

  .error {
    color: red;
    word-wrap: break-word;
    white-space: normal;
    padding: 10px;
  }

  button {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    display: flex;
    opacity: 0.8;
    width: 100%;
    padding: 5px 60px;

    &.cancel {
      top: 0;
      left: unset;
      right: 0;
      padding: 10px;
      width: min-content;
      height: min-content;
    }

    :deep(.p-button-icon) {
      font-size: $font-size-large;
    }
  }
}
</style>
