import { AxiosResponse } from "axios";
import { ApiControllerCore } from "./ApiControllerCore";
import { Constants } from "@/enums/Constants";
import { UserStatusViewModel } from "@/viewModels/UserStatusViewModel";
import { LoginViewModel } from "@/viewModels/LoginViewModel";
import { LoginData } from "@/dtos/data/LoginData";

export class UserStatusApiController extends ApiControllerCore {
  public login(loginData: LoginData): Promise<AxiosResponse<LoginViewModel>> {
    return super._internalPost(Constants.ApiEndpoints.USER_STATUS, loginData);
  }

  public logout(): Promise<AxiosResponse<LoginViewModel>> {
    return super._internalDelete(Constants.ApiEndpoints.USER_STATUS);
  }

  public getUserStatus(): Promise<AxiosResponse<UserStatusViewModel>> {
    return super._internalGet(Constants.ApiEndpoints.USER_STATUS);
  }
}
