import { defineStore } from "pinia";
import { LoginData } from "@/dtos/data/LoginData";
import { LoginReturnCode } from "@/enums/LoginReturnCode";
import { UserDto, UserStateDto } from "@/dtos/UserDtos";
import { ObjectHelper } from "@/helpers/ObjectHelper";
import { UserStatusApiController } from "@/api/UserStatusApiController";
import { UserModel } from "@/models/UserModel";
import { Language } from "@/enums/Language";
import { RoleType } from "@/enums/RoleType";
import { LanguageHelper } from "@/helpers/LanguageHelper";
import { RegisterData } from "@/dtos/data/RegisterData";
import { AxiosResponse } from "axios";
import { LoginViewModel } from "@/viewModels/LoginViewModel";

const apiController = new UserStatusApiController();

export const useUserStatusStore = defineStore("userStatus", {
  // =================================================
  // ||                   STATE                     ||
  // =================================================
  state: (): UserStateDto => ({
    isUserStatusRestored: false,
    userDto: {
      model: {
        id: 0,
        username: "Guest",
        password: "",
        passwordChanged: false,
        isPasswordChangePending: false,
        passwordResetCode: null,
        mfaSecret: null,
        mfaEnabled: false,
        notes: null,
        isActive: false,
        lastLoginDate: null,
        displayName: "Guest",
        language: Language.NONE,
        roleId: 0,
        role: {
          id: 0,
          name: "GUEST",
          roleType: RoleType.GUEST,
          createdAt: new Date(),
          updatedAt: null,
        },
        createdAt: new Date(),
        updatedAt: null,
      },
      isDirty: false,
      hasError: false,
      isLoading: false,
      validationErrorFields: [],
    },
  }),
  // =================================================
  // ||                   GETTERS                   ||
  // =================================================
  getters: {
    isAuthenticated(): boolean {
      return this.userDto.model.role.roleType === RoleType.ADMIN || this.userDto.model.role.roleType === RoleType.USER;
    },

    isAdmin(): boolean {
      return this.userDto.model.role.roleType === RoleType.ADMIN;
    },
  },
  actions: {
    /* -------------------------------------------
     * Log the user in with the provided input data.
     * -------------------------------------------- */
    async postLogin(loginData: LoginData): Promise<void> {
      this.userDto.isLoading = true;
      this.userDto.hasError = false;

      return new Promise<void>((resolve, reject) => {
        apiController
          .login(loginData)
          .then((success: AxiosResponse<LoginViewModel>) => {
            if (success.data.loginReturnCode === LoginReturnCode.USERNAMEPASSWORD_OK) {
              ObjectHelper.copyExistingPropsFromTo(success.data.user, this.userDto.model, UserModel.mappings);

              // update store front language if necessary.
              LanguageHelper.updateFrontendLanguage();

              resolve();
            } else {
              reject();
            }
          })
          .catch((error: any) => {
            this.userDto.hasError = true;
            reject(error);
          })
          .finally(() => {
            this.userDto.isLoading = false;
          });
      });
    },

    /* -------------------------------------------
     * Log the user in with the provided input data.
     * -------------------------------------------- */
    async postLogout(): Promise<void> {
      this.userDto.isLoading = true;
      this.userDto.hasError = false;

      return new Promise<void>((resolve, reject) => {
        apiController
          .logout()
          .then(() => {
            this.getUserStatus()
              .then(() => {
                // update store front language if necessary.
                LanguageHelper.updateFrontendLanguage();

                resolve();
              })
              .catch(reject);
          })
          .catch((error) => {
            this.userDto.hasError = true;
            reject(error);
          })
          .finally(() => {
            this.userDto.isLoading = false;
          });
      });
    },

    /* -------------------------------------------
     * Get User Session info from backend. Tells if user is logged in.
     * -------------------------------------------- */
    async getUserStatus(): Promise<void> {
      this.userDto.hasError = false;
      this.userDto.isLoading = true;

      return new Promise<void>((resolve, reject) => {
        return apiController
          .getUserStatus()
          .then((success) => {
            this.isUserStatusRestored = true;

            ObjectHelper.copyExistingPropsFromTo(success.data.user, this.userDto.model, UserModel.mappings);

            resolve();
          })
          .catch((error) => {
            this.userDto.hasError = true;
            reject(error);
          })
          .finally(() => {
            this.userDto.isLoading = false;
          });
      });
    },
  },
});
