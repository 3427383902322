<template>
  <WebFrontHeader></WebFrontHeader>

  <div class="wrapped-panel">
    <router-view></router-view>
  </div>
</template>

<script lang="ts">
import WebFrontHeader from "@/components/webfront/WebFrontHeader.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "WrappedPanel",
  components: {
    WebFrontHeader,
  },
});
</script>

<style scoped lang="scss">
.wrapped-panel {
  max-width: 500px;
  padding: 40px;
  margin: 100px auto;
  background-color: $sp-secondary-background-color;
}
</style>
