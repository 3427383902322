<template>
  <h1>Dashboard</h1>

  <h2 class="mt-0">Teilnahmen</h2>
  <div class="statistics" v-if="!isParticipationsLoading">
    <Card class="w-25">
      <template #content>
        <strong> <i class="pi pi-users"></i>Anzahl Teilnahmen Total</strong>
        <p>{{ participationsReport.totalParticipationsCount }}</p>
      </template>
    </Card>
    <Card class="w-25">
      <template #content>
        <strong> <i class="pi pi-user-minus"></i>Anzahl Verlierer (kein Gewinn)</strong>
        <p>{{ participationsReport.lostParticipationsCount }}</p>
      </template>
    </Card>
    <Card class="w-25">
      <template #content>
        <strong> <i class="pi pi-gift"></i>Anzahl Gewinner (noch nicht bestätigt)</strong>
        <p>{{ participationsReport.unconfirmedWinnersCount }}</p>
      </template>
    </Card>
    <Card class="w-25">
      <template #content>
        <strong> <i class="pi pi-gift"></i>Anzahl Gewinner (bestätigt)</strong>
        <p>{{ participationsReport.confirmedWinnersCount }}</p>
      </template>
    </Card>

    <Card class="w-50 security mb-5">
      <template #content>
        <strong> <i class="pi pi-times"></i>Anzahl Gewinner (abgelehnt)</strong>
        <p>{{ participationsReport.confirmedWinnersRejectedCount }}</p>
      </template>
    </Card>
    <Card class="w-50 sms mb-5">
      <template #content>
        <strong> <i class="pi pi-check"></i>Anzahl Gewinner (akzeptiert)</strong>
        <p>{{ participationsReport.confirmedWinnersAcceptedCount }}</p>
      </template>
    </Card>

    <Card class="w-50 content-center">
      <template #content>
        <strong> <i class="pi pi-gift"></i>Teilnehmerverhältnis</strong>
        <Chart v-if="pieChartOptions" type="pie" :data="participationDistributionChartData" :options="pieChartOptions" class="box" />
      </template>
    </Card>
    <Card class="w-50 content-center">
      <template #content>
        <strong> <i class="pi pi-gift"></i>Teilnahmen in den letzten Tagen</strong>
        <Chart v-if="chartOptions" type="bar" :data="participationTimelineChartData" :options="chartOptions" class="horizontal" />
      </template>
    </Card>
  </div>
  <Skeleton v-else height="200px"></Skeleton>

  <h2>Finanzen</h2>
  <div class="statistics" v-if="!isParticipationsLoading">
    <Card class="w-50">
      <template #content>
        <strong> <i class="pi pi-users"></i>Gesamtsumme Kassabon-Wert</strong>
        <p>CHF {{ participationsReport.totalPurchaseValue.toFixed(2) }}</p>
      </template>
    </Card>
  </div>
  <Skeleton v-else height="200px"></Skeleton>

  <h2>SMS-Versand</h2>
  <div class="statistics" v-if="!isSmsLoading">
    <Card class="w-50 sms">
      <template #content>
        <strong> <i class="pi pi-users"></i>Erfolgreich versendete SMS</strong>
        <p>{{ smsReport.amountOfSuccessfulSmsRequests }}</p>
      </template>
    </Card>
    <Card class="w-50 sms">
      <template #content>
        <strong> <i class="pi pi-users"></i>Fehlgeschlagene SMS</strong>
        <p>{{ smsReport.amountOfFailedSmsRequests }}</p>
      </template>
    </Card>
    <Card class="w-50 content-center">
      <template #content>
        <strong> <i class="pi pi-gift"></i>SMS-Versand</strong>
        <Chart v-if="pieChartOptions" type="pie" :data="smsDistributionChartData" :options="pieChartOptions" class="box" />
      </template>
    </Card>
  </div>
  <Skeleton v-else height="200px"></Skeleton>

  <h2>Sicherheit</h2>
  <div class="statistics" v-if="!isSecurityLoading">
    <Card class="w-50 security">
      <template #content>
        <strong> <i class="pi pi-users"></i>Teilnahmeversuche ausserhalb CH</strong>
        <p>{{ securityReport.failedAttemptsBecauseBadRequestOrigin }}</p>
      </template>
    </Card>
    <Card class="w-50 security">
      <template #content>
        <strong> <i class="pi pi-user-minus"></i>Teilnahmeversuche nach Überschreitung des Request-Limits</strong>
        <p>{{ securityReport.failedAttemptsBecauseIpLimitReached }}</p>
      </template>
    </Card>
    <Card class="w-50 content-center">
      <template #content>
        <strong> <i class="pi pi-gift"></i>Abgelehnte Teilnahmen</strong>
        <Chart v-if="pieChartOptions" type="pie" :data="securityDistributionChartData" :options="pieChartOptions" class="box" />
      </template>
    </Card>
  </div>
  <Skeleton v-else height="200px"></Skeleton>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { useReportingStore } from "@/stores/reportingStore";
import { ReportingViewModel } from "@/viewModels/ReportingViewModel";
import { AxiosResponse } from "axios";
import { ParticipationsReport } from "@/dtos/data/reports/ParticipationsReport";
import { SecurityReport } from "@/dtos/data/reports/SecurityReport";
import { SmsReport } from "@/dtos/data/reports/SmsReport";
import Skeleton from "primevue/skeleton";
import { storeToRefs } from "pinia";

export default defineComponent({
  name: "Dashboard",
  components: {
    Skeleton,
  },
  setup() {
    const reportingStore = useReportingStore();

    const chartOptions = ref();
    const pieChartOptions = ref();

    const participationDistributionChartData = ref();
    const participationTimelineChartData = ref();
    const securityDistributionChartData = ref();
    const smsDistributionChartData = ref();

    const participationsReport = ref<InstanceType<typeof ParticipationsReport>>(new ParticipationsReport());
    const securityReport = ref<InstanceType<typeof SecurityReport>>(new SecurityReport());
    const smsReport = ref<InstanceType<typeof SmsReport>>(new SmsReport());

    const { isParticipationsLoading, isSecurityLoading, isSmsLoading } = storeToRefs(reportingStore);

    // make initial API calls.
    onMounted(() => {
      chartOptions.value = setChartOptions();
      pieChartOptions.value = setPieChartOptions();

      // get participation reporting data.
      reportingStore.getParticipationsReport().then((result: AxiosResponse<ReportingViewModel>) => {
        participationsReport.value = result.data.participationsReport;
        participationDistributionChartData.value = setParticipationDistributionChartData();
        participationTimelineChartData.value = setParticipationTimelineChartData();
      });

      // get sms reporting data.
      reportingStore.getSmsReport().then((result: AxiosResponse<ReportingViewModel>) => {
        smsReport.value = result.data.smsReport;
        smsDistributionChartData.value = setSmsDistributionChartData();
      });

      // get security reporting data.
      reportingStore.getSecurityReport().then((result: AxiosResponse<ReportingViewModel>) => {
        securityReport.value = result.data.securityReport;
        securityDistributionChartData.value = setSecurityDistributionChartData();
      });
    });

    // =========== CHARTS ======================
    const setChartOptions = () => {
      // no specific chart options.
      return {};
    };
    const setPieChartOptions = () => {
      return {
        plugins: {
          legend: {
            labels: {
              usePointStyle: true,
            },
          },
        },
      };
    };

    // PARTICIAPTION CHARTS
    const setParticipationDistributionChartData = () => {
      const documentStyle = getComputedStyle(document.body);
      return {
        labels: ["Verlierer", "Gewinner (nicht bestätigt)", "Gewinner (bestätigt)", "Gewinner (abgelehnt)", "Gewinner (akzeptiert)"],
        datasets: [
          {
            label: "Anzahl",
            data: [
              participationsReport.value.lostParticipationsCount,
              participationsReport.value.unconfirmedWinnersCount,
              participationsReport.value.confirmedWinnersCount,
              participationsReport.value.confirmedWinnersRejectedCount,
              participationsReport.value.confirmedWinnersAcceptedCount,
            ],
            backgroundColor: [
              documentStyle.getPropertyValue("--gray-300"),
              documentStyle.getPropertyValue("--gray-500"),
              documentStyle.getPropertyValue("--cyan-400"),
              documentStyle.getPropertyValue("--red-400"),
              documentStyle.getPropertyValue("--green-400"),
            ],
          },
        ],
      };
    };
    const setParticipationTimelineChartData = () => {
      const documentStyle = getComputedStyle(document.body);
      return {
        labels: participationsReport.value.recentParticipationsTimeline.map((d) => d.date),
        datasets: [
          {
            label: "Teilnehmer an diesem Tag",
            data: participationsReport.value.recentParticipationsTimeline.map((d) => d.amount),
            backgroundColor: documentStyle.getPropertyValue("--gray-400"),
          },
        ],
      };
    };

    // SMS CHARTS
    const setSmsDistributionChartData = () => {
      const documentStyle = getComputedStyle(document.body);
      return {
        labels: ["Erfolgreiche SMS", "Fehlgeschlagene SMS"],
        datasets: [
          {
            label: "Anzahl",
            data: [smsReport.value.amountOfSuccessfulSmsRequests, smsReport.value.amountOfFailedSmsRequests],
            backgroundColor: [documentStyle.getPropertyValue("--green-400"), documentStyle.getPropertyValue("--red-200")],
          },
        ],
      };
    };

    // SECURITY CHARTS
    const setSecurityDistributionChartData = () => {
      const documentStyle = getComputedStyle(document.body);
      return {
        labels: ["Teilnahmen ausserhalb CH", "Request-Limit Überschreitungen"],
        datasets: [
          {
            label: "Anzahl",
            data: [securityReport.value.failedAttemptsBecauseBadRequestOrigin, securityReport.value.failedAttemptsBecauseIpLimitReached],
            backgroundColor: [documentStyle.getPropertyValue("--orange-400"), documentStyle.getPropertyValue("--red-400")],
          },
        ],
      };
    };

    return {
      isParticipationsLoading,
      isSecurityLoading,
      isSmsLoading,
      participationsReport,
      securityReport,
      smsReport,
      chartOptions,
      pieChartOptions,
      participationDistributionChartData,
      participationTimelineChartData,
      smsDistributionChartData,
      securityDistributionChartData,
    };
  },
});
</script>

<style scoped lang="scss">
h2 {
  margin-top: 50px;
}
.statistics {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 20px;
  row-gap: 20px;

  .security p {
    color: $sp-primary-color;
  }

  .sms p {
    color: var(--green-500);
  }

  > div {
    &.w-100 {
      width: 100%;
    }
    &.w-50 {
      width: calc(50% - 10px);
    }
    &.w-33 {
      width: calc(33% - 13px);
    }
    &.w-25 {
      width: calc(25% - 15px);
    }

    :deep(.p-card-content) {
      padding: 0;
    }

    i {
      margin-right: 10px;
    }

    p {
      margin: 5px 0;
      font-size: $font-size-large;
      color: $sp-accent-2-color;
    }

    :deep(.p-card-body) {
      canvas {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .p-chart {
      height: 20rem;
    }
  }
}
</style>
