import { UserModel } from "@/models/UserModel";
import { TypeMapping } from "../dtos/shared/TypeMapping";
import { ObjectHelper } from "./ObjectHelper";
import { RoleModel } from "@/models/RoleModel";
import { TranslationModel } from "@/models/TranslationModel";
import { TranslationDto } from "@/dtos/TranslationDtos";
import { UserDto } from "@/dtos/UserDtos";
import { EnumHelper } from "./EnumHelper";
import { LogEntryModel } from "@/models/LogEntryModel";
import { LogEntryDto } from "@/dtos/LogEntryDtos";
import { ParticipationDto } from "@/dtos/ParticipationDtos";
import { ParticipationModel } from "@/models/ParticipationModel";
import { FeedbackDto } from "@/dtos/FeedbackDtos";
import { FeedbackModel } from "@/models/FeedbackModel";

/**
 * Used for mapping specific types.
 */
export const TypeHelper = {
  mappers: {
    Date: (value: any) => new Date(value),
    Number: (value: any) => (value ? parseFloat(value) : 0),
    RoleModel: (value: any) => ObjectHelper.copyExistingPropsFromTo(value, new RoleModel(), RoleModel.mappings),
    UserDto: (value: any) => new UserDto(ObjectHelper.copyExistingPropsFromTo(value, value, UserModel.mappings)),
    UserModel: (value: any) => ObjectHelper.copyExistingPropsFromTo(value, new UserModel(), UserModel.mappings),
    TranslationDto: (value: any) => new TranslationDto(ObjectHelper.copyExistingPropsFromTo(value, value, TranslationModel.mappings)),
    TranslationModel: (value: any) => ObjectHelper.copyExistingPropsFromTo(value, new TranslationModel(), TranslationModel.mappings),
    LogEntryDto: (value: any) => new LogEntryDto(ObjectHelper.copyExistingPropsFromTo(value, value, LogEntryModel.mappings)),
    LogEntryModel: (value: any) => ObjectHelper.copyExistingPropsFromTo(value, new LogEntryModel(), LogEntryModel.mappings),
    ParticipationDto: (value: any) => new ParticipationDto(ObjectHelper.copyExistingPropsFromTo(value, value, ParticipationModel.mappings)),
    ParticipationModel: (value: any) => ObjectHelper.copyExistingPropsFromTo(value, new ParticipationModel(), ParticipationModel.mappings),
    FeedbackDto: (value: any) => new FeedbackDto(ObjectHelper.copyExistingPropsFromTo(value, value, FeedbackModel.mappings)),
    FeedbackModel: (value: any) => ObjectHelper.copyExistingPropsFromTo(value, new FeedbackModel(), FeedbackModel.mappings),
  },
};

/**
 * Used for some properties that appear in many entities.
 */
export const genericMappings: TypeMapping[] = [
  {
    key: "validFromDate",
    cast: (x) => TypeHelper.mappers.Date(x),
  },
  {
    key: "createdBy",
    cast: (x) => TypeHelper.mappers.UserModel(x),
  },
  {
    key: "updatedBy",
    cast: (x) => TypeHelper.mappers.UserModel(x),
  },
  {
    key: "createdAt",
    cast: (x) => TypeHelper.mappers.Date(x),
  },
  {
    key: "updatedAt",
    cast: (x) => TypeHelper.mappers.Date(x),
  },
  {
    key: "sortDirection",
    cast: (x) => EnumHelper.getSortDirection(x),
  },
];

/**
 * Used for some properties that appear in request/response base entities.
 */
export const baseResponseMappings: TypeMapping[] = [
  {
    key: "statusCode",
    cast: (x) => EnumHelper.getStatusCode(x),
  },
  {
    key: "loginReturnCode",
    cast: (x) => EnumHelper.getLoginReturnCode(x),
  },
  {
    key: "participationResult",
    cast: (x) => EnumHelper.getParticipationResult(x),
  },
  {
    key: "returnCode",
    cast: (x) => EnumHelper.getReturnCode(x),
  },
];
