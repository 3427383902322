import { Language } from "@/enums/Language";
import { useTranslationStore } from "@/stores/translationStore";
import { useUserStatusStore } from "@/stores/userStatusStore";

/**
 * Used for helping with languages.
 */
export const LanguageHelper = {
  /* -------------------------------------------
  Sets the global display language for the Store Front.
  ------------------------------------------- */
  updateFrontendLanguage() {
    const userStatusStore = useUserStatusStore();
    const translationStore = useTranslationStore();

    // overwrite language, this whole application is only available in german.
    translationStore.currentLanguage = Language.DE;
    return;

    const userLanguage = userStatusStore.userDto.model.language;
    const browserLanguage = navigator.language.toLowerCase();

    // change to supported language. Prioritize user language.
    if (userLanguage === Language.DE) {
      translationStore.currentLanguage = Language.DE;
      console.log(`Changed language to ${Language[translationStore.currentLanguage]}. (user)`);
    } else if (userLanguage === Language.FR) {
      translationStore.currentLanguage = Language.FR;
      console.log(`Changed language to ${Language[translationStore.currentLanguage]}. (user)`);
    } else if (browserLanguage === "de") {
      translationStore.currentLanguage = Language.DE;
      console.log(`Changed language to ${Language[translationStore.currentLanguage]}. (browser)`);
    } else if (browserLanguage === "fr") {
      translationStore.currentLanguage = Language.FR;
      console.log(`Changed language to ${Language[translationStore.currentLanguage]}. (browser)`);
    } else {
      // default to German.
      translationStore.currentLanguage = Language.DE;
      console.log(`Changed language to ${Language[translationStore.currentLanguage]}. (fallback)`);
    }
  },
};
