<template>
  <div class="title-panel">
    <h2>Teilnahme einsehen</h2>
  </div>

  <ParticipationsCreateEdit :id="id" :dto="dto"></ParticipationsCreateEdit>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { ParticipationDto } from "@/dtos/ParticipationDtos";
import { FormHelper } from "@/helpers/FormHelper";
import routerMixins from "@/mixins/routerMixins";
import ParticipationsCreateEdit from "@/components/controlpanel/participations/ParticipationsCreateEdit.vue";
import { ParticipationModel } from "@/models/ParticipationModel";
import { useParticipationStore } from "@/stores/participationStore";

/**
 * View that renders the the "edit" form for participations.
 */
export default defineComponent({
  name: "ParticipationsEdit",
  setup() {
    const participationStore = useParticipationStore();
    const route = useRoute();

    const formHelper = new FormHelper("/controlpanel/participations");

    // validate parameter.
    const id = routerMixins.tryGetParam(route.params.id, () => {
      formHelper.returnToList();
    });

    const dto = ref(new ParticipationDto(new ParticipationModel()));

    // get entity from store.
    onMounted(() => {
      participationStore.getParticipation(dto.value, id);
    });

    return {
      id,
      dto,
    };
  },
  components: { ParticipationsCreateEdit },
});
</script>

<style scoped lang="scss"></style>
