import { TypeHelper } from "@/helpers/TypeHelper";
import { BaseModel } from "./BaseModel";
import { RoleModel } from "./RoleModel";
import { IUserModel } from "./interfaces/IUserModel";
import { TypeMapping } from "@/dtos/shared/TypeMapping";
import { Language } from "@/enums/Language";

export class UserModel extends BaseModel implements IUserModel {
  username: string;
  password: string;
  passwordChanged: boolean;
  isPasswordChangePending: boolean;
  passwordResetCode?: string | null;
  mfaSecret: string | null;
  mfaEnabled: boolean;
  notes?: string | null;
  isActive: boolean;
  lastLoginDate?: Date | null;
  displayName: string;
  language: Language;
  roleId: number;
  role: RoleModel;

  constructor() {
    super();

    this.username = "";
    this.password = "";
    this.passwordChanged = false;
    this.isPasswordChangePending = false;
    this.passwordResetCode = null;
    this.mfaSecret = null;
    this.mfaEnabled = false;
    this.notes = null;
    this.isActive = false;
    this.lastLoginDate = null;
    this.displayName = "";
    this.language = Language.NONE;
    this.roleId = -1;
    this.role = new RoleModel();
  }

  public static readonly mappings: TypeMapping[] = [
    {
      key: "lastLoginDate",
      cast: (x) => TypeHelper.mappers.Date(x),
    },
    {
      key: "role",
      cast: (x) => TypeHelper.mappers.RoleModel(x),
    },
  ];
}
