import { FeedbackDto } from "@/dtos/FeedbackDtos";
import { BaseApiController } from "./BaseApiController";
import { Constants } from "@/enums/Constants";
import { FeedbackModel } from "@/models/FeedbackModel";
import { FeedbackListViewModel, FeedbackViewModel } from "@/viewModels/ViewModels";

export class FeedbackApiController extends BaseApiController<FeedbackModel, FeedbackDto, FeedbackViewModel, FeedbackListViewModel> {
  constructor() {
    super(Constants.ApiEndpoints.FEEDBACKS);
  }
}
