import { defineStore } from "pinia";
import { SortDirection } from "@/enums/SortDirection";
import { ParticipationApiController } from "@/api/ParticipationApiController";
import { ParticipationListDto } from "@/dtos/ParticipationDtos";
import { ParticipationDto } from "@/dtos/ParticipationDtos";
import { ParticipationListViewModel, ParticipationViewModel } from "@/viewModels/ViewModels";
import { ParticipationResultViewModel } from "@/viewModels/ParticipationResultViewModel";
import { ParticipationModel } from "@/models/ParticipationModel";
import { StoreApiProxy } from "@/helpers/StoreApiProxy";
import stringMixins from "@/mixins/stringMixins";
import { ExportType } from "@/enums/ExportType";
import { BaseDto } from "@/dtos/base/BaseDto";
import { ParticipationStatisticsViewModel } from "@/viewModels/ParticipationStatisticsViewModel";
import { ParticipationStatus } from "@/enums/ParticipationStatus";
import { FilterMatchMode } from "primevue/api";

const apiController = new ParticipationApiController();
const storeApiProxy = new StoreApiProxy<ParticipationModel, ParticipationDto, ParticipationListDto, ParticipationViewModel, ParticipationListViewModel>(
  apiController,
  ParticipationListDto.mappings,
  ParticipationDto.mappings
);

export const useParticipationStore = defineStore("participation", {
  state: () => ({
    filters: {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      sujet: { value: null, matchMode: FilterMatchMode.CONTAINS },
    },
    typeFilter: ParticipationStatus.NONE,
  }),
  actions: {
    /* -------------------------------------------
     * Get all participations from API.
     * -------------------------------------------- */
    async getParticipations(listDto: ParticipationListDto): Promise<void> {
      return storeApiProxy.apiGetMultiple(listDto);
    },

    /* -------------------------------------------
     * Get single participation from API.
     * -------------------------------------------- */
    async getParticipation(dto: ParticipationDto, id: number): Promise<void> {
      return storeApiProxy.apiGetSingle(dto, id);
    },

    /* -------------------------------------------
     * Updates one participation DTO with winner data.
     * -------------------------------------------- */
    async updateParticipation(dto: ParticipationDto): Promise<ParticipationViewModel> {
      dto.isLoading = true;
      dto.hasError = false;

      return new Promise<ParticipationViewModel>((resolve, reject) => {
        apiController
          .updateParticipation(dto.model)
          .then((success) => {
            resolve(success.data);
          })
          .catch((error) => {
            dto.hasError = true;
            reject(error);
          })
          .finally(() => {
            dto.isLoading = false;
          });
      });
    },

    /* -------------------------------------------
     * Updates one participation DTO with purchase value (control panel only).
     * -------------------------------------------- */
    async updateParticipationById(dto: ParticipationDto): Promise<void> {
      return storeApiProxy.apiUpdate(dto);
    },

    /* -------------------------------------------
     * Gets the statistics timeline for participations.
     * -------------------------------------------- */
    async getParticipationTimeline(dto: BaseDto): Promise<ParticipationStatisticsViewModel> {
      dto.isLoading = true;
      dto.hasError = false;

      return new Promise<ParticipationStatisticsViewModel>((resolve, reject) => {
        apiController
          .getParticipationTimeline()
          .then((success) => {
            resolve(success.data);
          })
          .catch((error) => {
            dto.hasError = true;
            reject(error);
          })
          .finally(() => {
            dto.isLoading = false;
          });
      });
    },

    /* -------------------------------------------
     * Creates a new participation DTO.
     * -------------------------------------------- */
    async createParticipation(dto: ParticipationDto): Promise<ParticipationResultViewModel> {
      dto.isLoading = true;
      dto.hasError = false;

      return new Promise<ParticipationResultViewModel>((resolve, reject) => {
        apiController
          .createParticipation(dto.model)
          .then((success) => {
            resolve(success.data);
          })
          .catch((error) => {
            dto.hasError = true;
            reject(error);
          })
          .finally(() => {
            dto.isLoading = false;
          });
      });
    },

    /* -------------------------------------------
     * Deletes an existing participation DTO.
     * -------------------------------------------- */
    async deleteParticipation(dto: ParticipationDto): Promise<void> {
      return storeApiProxy.apiDelete(dto);
    },

    /* -------------------------------------------
     * Exports all entities as PDF or XLSX.
     * -------------------------------------------- */
    async exportList(listDto: ParticipationListDto, exportType: ExportType): Promise<void> {
      return storeApiProxy.apiExport(listDto, exportType);
    },

    // ===== Mutations =====

    setSortColumn(listDto: ParticipationListDto, column: string): void {
      listDto.sortColumn = stringMixins.stripModelString(column);
    },

    setSortDirection(listDto: ParticipationListDto, direction: SortDirection): void {
      listDto.sortDirection = direction;
    },

    setPage(listDto: ParticipationListDto, page: number): void {
      listDto.page = page;
    },

    setFilter(listDto: ParticipationListDto, filter: string | null, typeFilter: number): void {
      listDto.filter = filter;
      listDto.typeFilter = typeFilter;
    },
  },
});
