import * as Yup from "yup";
import { BaseFormData } from "./BaseFormData";
import { toRaw } from "vue";
import { ParticipationModel } from "@/models/ParticipationModel";
import { ParticipationDto } from "../ParticipationDtos";
import { IParticipationEntryModel } from "@/models/interfaces/IParticipationEntryModel";
import { Constants } from "@/enums/Constants";

export class ParticipationEntryFormData extends BaseFormData<IParticipationEntryModel> {
  public phoneNumber: string;

  public originalData: ParticipationModel;

  constructor(dto: ParticipationDto) {
    super(dto);

    if (dto.model.id) {
      this.isInitializedProperly = true;

      if (dto.model.id === -1) {
        this.isInitializedWithNewEntity = true;
      }
    }

    this.phoneNumber = dto.model.phoneNumber;

    this.originalData = structuredClone(toRaw(dto.model));

    this.formSchema = Yup.object({
      phoneNumber: Yup.string().matches(Constants.Regexes.PHONE_NUMBER).required(),
    });
  }
}
