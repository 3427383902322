<template>
  <div v-if="form.isReady()">
    <form @submit.prevent="" @change="validator.validateForm(form as ParticipationWinnerFormData, dto)">
      <Panel header="Teilnahme-Informationen">
        <div class="form-row split">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-calendar"></i>
            <Calendar id="createdAt" :disabled="true" date-format="dd.mm.yy" showTime hourFormat="24" v-model="dto.model.createdAt" />
            <label for="createdAt">Erstellt am</label>
          </span>
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-calendar"></i>
            <Calendar id="createdAt" :disabled="true" date-format="dd.mm.yy" showTime hourFormat="24" v-model="dto.model.updatedAt" />
            <label for="updatedAt">Zuletzt aktualisiert am</label>
          </span>
        </div>
        <div class="form-row split">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-phone"></i>
            <InputText type="text" id="phoneNumber" :disabled="true" v-model="dto.model.phoneNumber" />
            <label for="phoneNumber">Handynummer</label>
          </span>
          <span class="p-float-label p-input-icon-left" v-if="dto.model.participationStatus !== ParticipationStatus.PARTICIPATED">
            <i class="pi pi-calendar"></i>
            <Calendar id="createdAt" :disabled="true" date-format="dd.mm.yy" showTime hourFormat="24" v-model="dto.model.wonDate" />
            <label for="createdAt">Gewonnen am</label>
          </span>
        </div>
        <div class="form-row split">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-code"></i>
            <InputText type="text" id="winCode" :disabled="true" v-model="dto.model.winCode" />
            <label for="winCode">Gewinn-Code</label>
          </span>
          <span></span>
        </div>
        <div class="form-row">
          <span>
            <Tag v-if="dto.model.participationStatus === ParticipationStatus.PARTICIPATED" class="gray">kein Gewinner</Tag>
            <Tag v-else-if="dto.model.participationStatus === ParticipationStatus.WINNER_DECLARED" class="gray">Gewinner (noch nicht bestätigt)</Tag>
            <Tag v-else-if="dto.model.participationStatus === ParticipationStatus.WINNER_CONFIRMED" :severity="'info'">Gewinner (bestätigt)</Tag>
            <Tag v-else-if="dto.model.participationStatus === ParticipationStatus.WINNER_CONFIRMED_ACCEPTED" :severity="'success'">Gewinner (akzeptiert)</Tag>
            <Tag v-else-if="dto.model.participationStatus === ParticipationStatus.WINNER_CONFIRMED_REJECTED" :severity="'warning'">Gewinner (abgelehnt)</Tag>
          </span>
        </div>
      </Panel>

      <div class="split-panel" v-if="canEdit">
        <Panel header="Personalien">
          <div class="form-row">
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-user"></i>
              <InputText type="text" id="firstname" :disabled="true" v-model="dto.model.firstname" />
              <label for="firstname">Vorname</label>
            </span>
          </div>

          <div class="form-row">
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-user"></i>
              <InputText type="text" id="firstname" :disabled="true" v-model="dto.model.firstname" />
              <label for="firstname">Vorname</label>
            </span>
          </div>

          <div class="form-row split large-small">
            <div>
              <span class="p-float-label p-input-icon-left">
                <i class="pi pi-home"></i>
                <InputText type="text" id="street" :disabled="true" v-model="dto.model.street" />
                <label for="street">Strasse</label>
              </span>
            </div>
            <div>
              <span class="p-float-label p-input-icon-left">
                <i class="pi pi-home"></i>
                <InputText type="text" id="streetNumber" :disabled="true" v-model="dto.model.streetNumber" />
                <label for="streetNumber">Hausnr.</label>
              </span>
            </div>
          </div>

          <div class="form-row split">
            <div>
              <span class="p-float-label p-input-icon-left">
                <i class="pi pi-map-marker"></i>
                <InputNumber type="text" id="zipCode" :disabled="true" :use-grouping="false" v-model="dto.model.zipCode" />
                <label for="zipCode">PLZ</label>
              </span>
            </div>
            <div>
              <span class="p-float-label p-input-icon-left">
                <i class="pi pi-map-marker"></i>
                <InputText type="text" id="city" :disabled="true" v-model="dto.model.city" />
                <label for="city">Ort</label>
              </span>
            </div>
          </div>
        </Panel>

        <Panel header="Auswertung">
          <div class="form-row">
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-exclamation-circle"></i>
              <Dropdown v-model="form.participationStatus" :options="participationStatusOptions" :disabled="dto.isLoading" option-value="value">
                <template #value="slotProps">
                  <div v-if="slotProps.value" class="flex align-items-center">
                    <div>{{ participationStatusOptions.find((o) => o.value == slotProps.value)?.text }}</div>
                  </div>
                  <div v-else>&nbsp;</div>
                </template>
                <template #option="slotProps">
                  <div v-if="slotProps.option">{{ slotProps.option.text }}</div>
                  <div v-else>&nbsp;</div>
                </template>
              </Dropdown>
              <label for="participationStatus">Status</label>
            </span>
          </div>

          <div class="form-row">
            <span class="p-float-label p-input-icon-left">
              <i class="pi pi-money-bill"></i>
              <InputNumber id="purchaseValue" :disabled="dto.isLoading" :use-grouping="false" mode="currency" currency="CHF" v-model="dto.model.purchaseValue" class="highlighted" />
              <label for="purchaseValue">Kaufbetrag</label>
            </span>
          </div>

          <div class="button-panel">
            <div>
              <Button
                type="submit"
                label="Ablehnen und Speichern"
                icon="pi pi-save"
                severity="warning"
                @click="onRejectAndSave()"
                :disabled="!form.isValidForm() || dto.isLoading"
                :loading="dto.isLoading"
              >
              </Button>
              <Button
                type="submit"
                label="Akzeptieren und Speichern"
                icon="pi pi-save"
                severity="success"
                @click="onAcceptAndSave()"
                :disabled="!form.isValidForm() || dto.isLoading"
                :loading="dto.isLoading"
              >
              </Button>
            </div>
          </div>
        </Panel>
      </div>

      <div class="split-panel" v-if="canEdit">
        <Panel header="Kassabon">
          <div class="form-row">
            <a v-if="isPdf" :href="dto.model.receiptImage" :download="`kassabon-${dto.model.phoneNumber}.pdf`" class="button">Download PDF</a>
            <Image v-else :src="dto.model.receiptImage" preview></Image>
          </div>
        </Panel>
      </div>
    </form>

    <div class="button-panel">
      <div></div>
      <div>
        <Button type="button" label="Abbrechen" :severity="'secondary'" @click="formHelper.returnToList()"></Button>
        <Button type="submit" label="Speichern" icon="pi pi-save" @click="onSave()" :disabled="!form.isValidForm() || dto.isLoading || !canEdit" :loading="dto.isLoading"></Button>
      </div>
    </div>
  </div>

  <div v-else-if="dto.isLoading">
    <LoadingSkeleton></LoadingSkeleton>
  </div>

  <div v-else>
    <Message severity="warn" :closable="false">Es existiert keine Entität mit Id '{{ id }}'.</Message>
    <div>
      <Button type="button" label="Zurück zur Liste" :severity="'secondary'" @click="formHelper.returnToList()"></Button>
    </div>
  </div>

  <Toast>
    <template #message="slotProps">
      <div class="controlpanel-toast">
        <div class="p-toast-summary">{{ slotProps.message.summary }}</div>
        <div class="p-toast-detail">{{ slotProps.message.detail }}</div>
        <Button class="confirm-button" :size="'small'" label="Zurück zur Liste" :severity="'success'" @click="formHelper.returnToList()"></Button>
      </div>
    </template>
  </Toast>
</template>

<script lang="ts">
import { computed, defineComponent, ref, toRefs, watch } from "vue";
import { useParticipationStore } from "@/stores/participationStore";
import { ParticipationWinnerFormData } from "@/dtos/data/ParticipationWinnerFormData";
import { ParticipationDto } from "@/dtos/ParticipationDtos";
import { useToast } from "primevue/usetoast";
import { FormHelper } from "@/helpers/FormHelper";
import { Validator } from "@/helpers/Validator";
import { ToastMessageOptions } from "primevue/toast";
import { IParticipationModel } from "@/models/interfaces/IParticipationModel";
import LoadingSkeleton from "@/components/shared/LoadingSkeleton.vue";
import { ObjectHelper } from "@/helpers/ObjectHelper";
import { ParticipationStatus } from "@/enums/ParticipationStatus";
import { DropdownOption, EnumHelper } from "@/helpers/EnumHelper";

/**
 * A shared component used for editing (and creating) participations.
 */
export default defineComponent({
  name: "ParticipationsCreateEdit",
  props: {
    id: Number,
    dto: {
      type: ParticipationDto,
      required: true,
    },
  },
  components: { LoadingSkeleton },
  methods: {
    async onSave() {
      const participationStore = useParticipationStore();

      this.form.shouldValidate = true;

      // update values in store.
      ObjectHelper.copyExistingPropsFromTo(this.form, this.dto.model);

      // validate before save.
      return this.validator.validateForm(this.form as ParticipationWinnerFormData, this.dto).then(() => {
        if (this.form.isExistingOrNew()) {
          // send PUT request and show toast if successful.
          return participationStore.updateParticipationById(this.dto as ParticipationDto).then(async () => {
            this.showToast({
              life: 3000,
              severity: "success",
              summary: "Erfolgreich gespeichert",
              detail: "Die Teilnahme wurde erfolgreich aktualisiert.",
            });
          });
        }
      });
    },

    async onAcceptAndSave() {
      this.form.participationStatus = ParticipationStatus.WINNER_CONFIRMED_ACCEPTED;
      this.onSave().then(() => {
        this.$router.push("/controlpanel/participations");
      });
    },

    async onRejectAndSave() {
      this.form.participationStatus = ParticipationStatus.WINNER_CONFIRMED_REJECTED;
      this.onSave().then(() => {
        this.$router.push("/controlpanel/participations");
      });
    },

    async downloadReceiptImageAsPdf() {
      window.location.href = this.dto.model.receiptImage;
    },
  },
  setup(props) {
    const toast = useToast();

    const validator = new Validator<IParticipationModel>();
    const formHelper = new FormHelper("/controlpanel/participations");

    // get entity from props.
    const { id, dto } = toRefs(props);

    const participationStatusOptions = [
      {
        text: "Gewinner",
        value: ParticipationStatus.WINNER_CONFIRMED,
      },
      {
        text: "Gewinner (abgelehnt)",
        value: ParticipationStatus.WINNER_CONFIRMED_REJECTED,
      },
      {
        text: "Gewinner (akzeptiert)",
        value: ParticipationStatus.WINNER_CONFIRMED_ACCEPTED,
      },
    ] as DropdownOption<ParticipationStatus>[];

    // set up as form state as reactive object.
    const form = ref(new ParticipationWinnerFormData(dto.value));

    const canEdit = computed(() => {
      return (
        dto.value.model.participationStatus !== ParticipationStatus.NONE &&
        dto.value.model.participationStatus !== ParticipationStatus.PARTICIPATED &&
        dto.value.model.participationStatus !== ParticipationStatus.WINNER_DECLARED
      );
    });
    const isPdf = computed(() => dto.value.model.receiptImage.startsWith("data:application/pdf"));

    // setup form watchers.
    watch(dto.value, (newValue) => (form.value = new ParticipationWinnerFormData(newValue)));

    const showToast = (toastOptions: ToastMessageOptions) => {
      toast.add(toastOptions);
    };

    return {
      id,
      dto,
      form,
      formHelper,
      validator,
      showToast,
      canEdit,
      isPdf,
      participationStatusOptions,
      ParticipationStatus,
    };
  },
});
</script>

<style scoped lang="scss">
.split-panel {
  display: flex;
  flex-direction: column;
  width: calc(50% - 20px);
  row-gap: 20px;
}

form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 40px;
  column-gap: 40px;

  .p-panel {
    width: 100%;
  }
}
:deep(.p-panel-content) {
  padding-top: 30px;

  // overwrite some img hover preview stylings.
  .p-image {
    width: fit-content !important;

    img {
      width: 100%;
      max-width: 600px;
      max-height: 600px;
    }
  }
  .p-image-preview-indicator {
    margin: 0 !important;
  }
}

.p-tag {
  border-radius: 6px;
  font-size: $font-size-normal;

  &.gray {
    background: grey !important;
  }
}

.highlighted {
  border: 2px solid var(--sp-accent-2-color);
}

i {
  z-index: 1;
}
</style>
