import Axios, { AxiosResponse } from "axios";
import { ApiControllerCore } from "./ApiControllerCore";
import { BaseListDto } from "@/dtos/base/BaseListDto";
import { BaseModel } from "@/models/BaseModel";
import { ExportType } from "@/enums/ExportType";

export abstract class BaseApiController<TModel, TDto, TViewModel, TListViewModel> extends ApiControllerCore {
  protected apiEndpoint: string;

  constructor(apiEndpoint: string) {
    super();

    this.apiEndpoint = apiEndpoint;
  }

  public async get(dto: BaseListDto): Promise<AxiosResponse<TListViewModel>> {
    const urlQuery = super.createUrlQueryForList(dto);

    return super._internalGet(`${this.apiEndpoint}${urlQuery}`);
  }

  public async getActive(): Promise<AxiosResponse<TListViewModel>> {
    return super._internalGet(`${this.apiEndpoint}/active`);
  }

  public async getSingle(id: number): Promise<AxiosResponse<TViewModel>> {
    return super._internalGet(`${this.apiEndpoint}/${id}`);
  }

  public async getSingleActive(id: number): Promise<AxiosResponse<TViewModel>> {
    return super._internalGet(`${this.apiEndpoint}/${id}/active`);
  }

  public async update(id: number, model: BaseModel): Promise<AxiosResponse<TViewModel>> {
    return super._internalPut(`${this.apiEndpoint}/${id}`, model);
  }

  public async create(model: BaseModel): Promise<AxiosResponse<TViewModel>> {
    return super._internalPost(this.apiEndpoint, model);
  }

  public async delete(id: number): Promise<AxiosResponse<TViewModel>> {
    return super._internalDelete(`${this.apiEndpoint}/${id}`);
  }

  public async export(exportType: ExportType): Promise<AxiosResponse<Blob>> {
    return Axios.get(`${this.apiEndpoint}/export/${ExportType[exportType].toLowerCase()}`, {
      responseType: "arraybuffer",
    });
  }
}
