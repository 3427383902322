import { TypeMapping } from "@/dtos/shared/TypeMapping";
import { BaseModel } from "./BaseModel";
import { ITranslationModel } from "./interfaces/ITranslationModel";
import { UserModel } from "./UserModel";

export class TranslationModel extends BaseModel implements ITranslationModel {
  translationKey: string;
  languageDe: string;
  createdById: number;
  createdBy: UserModel;
  updatedById: number | null;
  updatedBy: UserModel | null;

  constructor() {
    super();

    this.translationKey = "";
    this.languageDe = "";
    this.createdById = -1;
    this.createdBy = new UserModel();
    this.updatedById = null;
    this.updatedBy = null;
  }

  public static readonly mappings: TypeMapping[] = [];
}
