import { defineStore } from "pinia";
import { SortDirection } from "@/enums/SortDirection";
import { FeedbackApiController } from "@/api/FeedbackApiController";
import { FeedbackListDto } from "@/dtos/FeedbackDtos";
import { FeedbackDto } from "@/dtos/FeedbackDtos";
import { FeedbackListViewModel, FeedbackViewModel } from "@/viewModels/ViewModels";
import { FeedbackModel } from "@/models/FeedbackModel";
import { StoreApiProxy } from "@/helpers/StoreApiProxy";
import stringMixins from "@/mixins/stringMixins";
import { ExportType } from "@/enums/ExportType";

const apiController = new FeedbackApiController();
const storeApiProxy = new StoreApiProxy<FeedbackModel, FeedbackDto, FeedbackListDto, FeedbackViewModel, FeedbackListViewModel>(apiController, FeedbackListDto.mappings, FeedbackDto.mappings);

export const useFeedbackStore = defineStore("feedback", {
  actions: {
    /* -------------------------------------------
     * Get all feedbacks from API.
     * -------------------------------------------- */
    async getFeedbacks(listDto: FeedbackListDto): Promise<void> {
      return storeApiProxy.apiGetMultiple(listDto);
    },

    /* -------------------------------------------
     * Get single feedback from API.
     * -------------------------------------------- */
    async getFeedback(dto: FeedbackDto, id: number): Promise<void> {
      return storeApiProxy.apiGetSingle(dto, id);
    },

    /* -------------------------------------------
     * Updates one feedback DTO with winner data.
     * -------------------------------------------- */
    async updateFeedback(dto: FeedbackDto): Promise<void> {
      return storeApiProxy.apiUpdate(dto);
    },

    /* -------------------------------------------
     * Creates a new feedback DTO.
     * -------------------------------------------- */
    async createFeedback(dto: FeedbackDto): Promise<void> {
      return storeApiProxy.apiCreate(dto);
    },

    /* -------------------------------------------
     * Deletes an existing feedback DTO.
     * -------------------------------------------- */
    async deleteFeedback(dto: FeedbackDto): Promise<void> {
      return storeApiProxy.apiDelete(dto);
    },

    /* -------------------------------------------
     * Exports all entities as PDF or XLSX.
     * -------------------------------------------- */
    async exportList(listDto: FeedbackListDto, exportType: ExportType): Promise<void> {
      return storeApiProxy.apiExport(listDto, exportType);
    },

    // ===== Mutations =====

    setSortColumn(listDto: FeedbackListDto, column: string): void {
      listDto.sortColumn = stringMixins.stripModelString(column);
    },

    setSortDirection(listDto: FeedbackListDto, direction: SortDirection): void {
      listDto.sortDirection = direction;
    },

    setPage(listDto: FeedbackListDto, page: number): void {
      listDto.page = page;
    },

    setFilter(listDto: FeedbackListDto, filter: string | null): void {
      listDto.filter = filter;
    },
  },
});
