export class ParticipationsReport {
  totalParticipationsCount: number = 0;
  lostParticipationsCount: number = 0;
  unconfirmedWinnersCount: number = 0;
  confirmedWinnersCount: number = 0;
  confirmedWinnersAcceptedCount: number = 0;
  confirmedWinnersRejectedCount: number = 0;
  totalPurchaseValue: number = 0;
  recentParticipationsTimeline: any[] = [];
}
