import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Skeleton = _resolveComponent("Skeleton")!

  return (_ctx.isDetails)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        _createVNode(_component_Skeleton, { class: "mb-2" }),
        _createVNode(_component_Skeleton, {
          width: "10rem",
          class: "mb-2"
        }),
        _createVNode(_component_Skeleton, {
          width: "15rem",
          class: "mb-2"
        }),
        _createVNode(_component_Skeleton, {
          width: "5rem",
          class: "mb-2"
        }),
        _createVNode(_component_Skeleton, {
          height: "2rem",
          class: "mb-2"
        }),
        _createVNode(_component_Skeleton, {
          width: "15rem",
          class: "mb-2"
        }),
        _createVNode(_component_Skeleton, {
          height: "2rem",
          class: "mb-2"
        }),
        _createVNode(_component_Skeleton, {
          width: "5rem",
          class: "mb-2"
        })
      ], 64))
    : (_ctx.isBlock)
      ? (_openBlock(), _createBlock(_component_Skeleton, {
          key: 1,
          width: _ctx.width,
          height: _ctx.height
        }, null, 8, ["width", "height"]))
      : (_openBlock(), _createBlock(_component_Skeleton, {
          key: 2,
          class: "mb-2",
          height: _ctx.height
        }, null, 8, ["height"]))
}