export enum LoginReturnCode {
  NONE = 0,
  INVALID = 1,
  USERNAMEPASSWORD_OK = 2,
  MFA_OK = 3,
  CHANGEPASSWORD = 4,
  PASSWORDS_DO_NOT_MATCH = 5,
  CURRENT_PASSWORD_DOES_NOT_MATCH = 6,
  INVALID_VALUES = 7,
  PASSWORD_CHANGE_OK = 8,
  INVALID_PASSWORD_COMPLEXITY = 9,
  ALREADY_LOGGED_IN = 10,
}
