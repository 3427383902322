import { createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, RouteLocationNormalizedLoaded, RouteRecordRaw, RouterScrollBehavior } from "vue-router";
import { useUserStatusStore } from "@/stores/userStatusStore";
import { useTranslationStore } from "@/stores/translationStore";
import { LanguageHelper } from "@/helpers/LanguageHelper";

import WebFront from "@/views/WebFront.vue";
import WebFrontLandingPage from "@/views/webfront/LandingPage.vue";
import WebFrontResultWon from "@/views/webfront/ResultWon.vue";
import WebFrontResultWonConfirm from "@/views/webfront/ResultWonConfirm.vue";
import WebFrontResultWonConfirmFeedback from "@/views/webfront/ResultWonConfirmFeedback.vue";
import WebFrontResultRejected from "@/views/webfront/ResultRejected.vue";
import WebFrontResultLost from "@/views/webfront/ResultLost.vue";
import WebFrontTermsAndConditions from "@/views/webfront/TermsAndConditions.vue";
import WebFrontPageNotFound from "@/views/webfront/PageNotFound.vue";

import ControlPanel from "@/views/ControlPanel.vue";
import ControlPanelDashboard from "@/views/controlpanel/Dashboard.vue";
import ControlPanelTranslations from "@/views/controlpanel/translations/Translations.vue";
import ControlPanelTranslationsList from "@/views/controlpanel/translations/TranslationsList.vue";
import ControlPanelTranslationsEdit from "@/views/controlpanel/translations/TranslationsEdit.vue";
import ControlPanelParticipations from "@/views/controlpanel/participations/Participations.vue";
import ControlPanelParticipationsList from "@/views/controlpanel/participations/ParticipationsList.vue";
import ControlPanelParticipationsEdit from "@/views/controlpanel/participations/ParticipationsEdit.vue";
import ControlPanelLogEntries from "@/views/controlpanel/logEntries/LogEntries.vue";
import ControlPanelLogEntriesList from "@/views/controlpanel/logEntries/LogEntriesList.vue";
import ControlPanelLogEntriesEdit from "@/views/controlpanel/logEntries/LogEntriesEdit.vue";
import ControlPanelFeedbacks from "@/views/controlpanel/feedbacks/Feedbacks.vue";
import ControlPanelFeedbacksList from "@/views/controlpanel/feedbacks/FeedbacksList.vue";
import ControlPanelFeedbacksEdit from "@/views/controlpanel/feedbacks/FeedbacksEdit.vue";

import WrappedPanel from "@/views/shared/WrappedPanel.vue";
import Login from "@/views/shared/Login.vue";

/**
 * Defines all frontend routes and routing behaviours.
 */
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "WebFront",
    component: WebFront,
    children: [
      {
        name: "WebFrontLandingPage",
        path: "",
        component: WebFrontLandingPage,
      },
      {
        name: "WebFrontResultLost",
        path: "result-lost",
        component: WebFrontResultLost,
        meta: { requireUserPermission: true },
      },
      {
        name: "WebFrontResultRejected",
        path: "result-rejected",
        component: WebFrontResultRejected,
        meta: { requireUserPermission: true },
      },
      {
        name: "WebFrontResultWon",
        path: "result-won",
        component: WebFrontResultWon,
        meta: { requireUserPermission: true },
      },
      {
        name: "WebFrontResultWonConfirm",
        path: "result-won-confirm",
        component: WebFrontResultWonConfirm,
      },
      {
        name: "WebFrontResultWonConfirmFeedback",
        path: "result-won-feedback",
        component: WebFrontResultWonConfirmFeedback,
        meta: { requireUserPermission: true },
      },
      {
        name: "WebFrontTermsAndConditions",
        path: "terms-and-conditions",
        component: WebFrontTermsAndConditions,
      },
      {
        path: "/:pathMatch(.*)*",
        name: "PageNotFound",
        component: WebFrontPageNotFound,
      },
    ],
  },
  {
    path: "/",
    name: "WrappedPanel",
    component: WrappedPanel,
    children: [
      {
        path: "login",
        name: "Login",
        component: Login,
      },
    ],
  },
  {
    path: "/controlpanel",
    name: "ControlPanel",
    component: ControlPanel,
    meta: {
      requireAdminPermission: true,
    },
    children: [
      {
        path: "",
        name: "ControlPanelDashboard",
        meta: { requireAdminPermission: true },
        component: ControlPanelDashboard,
      },
      {
        path: "translations",
        name: "ControlPanelTranslations",
        meta: { requireAdminPermission: true },
        component: ControlPanelTranslations,
        children: [
          {
            path: "",
            name: "ControlPanelTranslationsList",
            meta: { requireAdminPermission: true },
            component: ControlPanelTranslationsList,
          },
          {
            path: "edit/:id",
            name: "ControlPanelTranslationsEdit",
            meta: { requiresAdminPermission: true },
            component: ControlPanelTranslationsEdit,
          },
        ],
      },
      {
        path: "feedbacks",
        name: "ControlPanelFeedbacks",
        meta: { requireAdminPermission: true },
        component: ControlPanelFeedbacks,
        children: [
          {
            path: "",
            name: "ControlPanelFeedbacksList",
            meta: { requireAdminPermission: true },
            component: ControlPanelFeedbacksList,
          },
          {
            path: "edit/:id",
            name: "ControlPanelFeedbacksEdit",
            meta: { requiresAdminPermission: true },
            component: ControlPanelFeedbacksEdit,
          },
        ],
      },
      {
        path: "participations",
        name: "ControlPanelParticipations",
        meta: { requireAdminPermission: true },
        component: ControlPanelParticipations,
        children: [
          {
            path: "",
            name: "ControlPanelParticipationsList",
            meta: { requireAdminPermission: true },
            component: ControlPanelParticipationsList,
          },
          {
            path: "edit/:id",
            name: "ControlPanelParticipationsEdit",
            meta: { requiresAdminPermission: true },
            component: ControlPanelParticipationsEdit,
          },
        ],
      },
      {
        path: "logentries",
        name: "ControlPanelLogEntries",
        meta: { requireAdminPermission: true },
        component: ControlPanelLogEntries,
        children: [
          {
            path: "",
            name: "ControlPanelLogEntriesList",
            meta: { requireAdminPermission: true },
            component: ControlPanelLogEntriesList,
          },
          {
            path: "edit/:id",
            name: "ControlPanelLogEntriesEdit",
            meta: { requiresAdminPermission: true },
            component: ControlPanelLogEntriesEdit,
          },
        ],
      },
    ],
  },
];

// return to top of page when navigating.
const scrollBehavior: RouterScrollBehavior = (to: any, from: any, savedPosition: any) => {
  if (savedPosition) return savedPosition;
  if (to.hash) return { selector: to.hash };

  return { left: 0, top: 0 };
};

// create router component.
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: scrollBehavior,
});

// check if user is authenticated for routes that require authentication.
router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const userStatusStore = useUserStatusStore();
  const translationStore = useTranslationStore();

  // first get user, if not already fetched.
  await userStatusStore.getUserStatus();

  // update store front language if necessary.
  LanguageHelper.updateFrontendLanguage();

  // then get translations, if not already fetched.
  if (!translationStore.isTranslationsRestored) {
    await translationStore.getActiveTranslations();
  }

  if (to.matched.some((record) => record.meta.requireAdminPermission) && !userStatusStore.isAdmin) {
    // redirect to /login if requireAdminPermission is true.
    next("/login");
  } else if (to.matched.some((record) => record.meta.requireUserPermission) && !userStatusStore.isAuthenticated) {
    // redirect to landing page.
    next("/");
  } else {
    // continue as normal if not.
    next();
  }
});

export default router;
