import { TypeMapping } from "@/dtos/shared/TypeMapping";
import { BaseModel } from "./BaseModel";
import { IFeedbackModel } from "./interfaces/IFeedbackModel";
import { TypeHelper } from "@/helpers/TypeHelper";

export class FeedbackModel extends BaseModel implements IFeedbackModel {
  public response1: string;
  public response2: string;

  constructor() {
    super();

    this.response1 = "";
    this.response2 = "";
  }

  public static readonly mappings: TypeMapping[] = [];
}
