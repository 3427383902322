import { ParticipationDto } from "@/dtos/ParticipationDtos";
import { BaseApiController } from "./BaseApiController";
import { Constants } from "@/enums/Constants";
import { ParticipationModel } from "@/models/ParticipationModel";
import { ParticipationListViewModel, ParticipationViewModel } from "@/viewModels/ViewModels";
import { ParticipationResultViewModel } from "@/viewModels/ParticipationResultViewModel";
import { AxiosResponse } from "axios";
import { ParticipationStatisticsViewModel } from "@/viewModels/ParticipationStatisticsViewModel";

export class ParticipationApiController extends BaseApiController<ParticipationModel, ParticipationDto, ParticipationViewModel, ParticipationListViewModel> {
  constructor() {
    super(Constants.ApiEndpoints.PARTICIPATIONS);
  }

  public updateParticipation(model: ParticipationModel): Promise<AxiosResponse<ParticipationViewModel>> {
    return super._internalPut(Constants.ApiEndpoints.PARTICIPATIONS, model);
  }

  public createParticipation(model: ParticipationModel): Promise<AxiosResponse<ParticipationResultViewModel>> {
    return super._internalPost(Constants.ApiEndpoints.PARTICIPATIONS, model);
  }

  public getParticipationTimeline(): Promise<AxiosResponse<ParticipationStatisticsViewModel>> {
    return super._internalGet(`${this.apiEndpoint}/statistics`);
  }
}
