<template>
  <span ref="contentRef"></span>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { Translator } from "@/helpers/Translator";

/**
 * Simple text translator component that supports HTML strings.
 */
export default defineComponent({
  name: "Translate",
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const contentRef = ref();

    // set innerHTML to support HTML strings as well.
    watch(contentRef, () => {
      if (contentRef.value) {
        const translatedText = Translator.translate(props.text);
        contentRef.value.innerHTML = translatedText;
      }
    });

    return {
      contentRef,
    };
  },
});
</script>

<style scoped lang="scss"></style>
