<template>
  <ControlPanelHeader></ControlPanelHeader>
  <div id="controlpanel">
    <ControlPanelNavigation></ControlPanelNavigation>
    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ControlPanelNavigation from "@/components/controlpanel/ControlPanelNavigation.vue";
import ControlPanelHeader from "@/components/controlpanel/ControlPanelHeader.vue";

export default defineComponent({
  name: "ControlPanel",
  components: {
    ControlPanelNavigation,
    ControlPanelHeader,
  },
});
</script>

<style lang="scss">
#controlpanel button,
.p-toast button {
  margin-top: 20px;
  font-family: Arial, sans-serif !important;
}
</style>

<style scoped lang="scss">
#controlpanel {
  display: flex;
  margin-bottom: 40px;
  font-family: Arial, sans-serif;
  color: $sp-controlpanel-foreground-color;

  h1,
  h2,
  h3 {
    font-weight: bold;
  }

  :deep(.content) {
    width: calc(100% - 220px);
    padding: 20px;
    margin: 100px 0 0 220px;

    .title-panel {
      align-items: center;
      justify-content: space-between;
      display: flex;
      margin-bottom: 2rem;
      gap: 0.5rem;
    }

    .p-datatable-tbody {
      tr {
        &:hover {
          background: $sp-secondary-background-color;
        }

        button {
          margin: 0;
        }
      }
    }

    button {
      font-family: Arial, sans-serif !important;
      margin-bottom: 20px;
    }

    .button-panel {
      display: flex;
      justify-content: flex-end;
      margin: 20px 0;

      button:not(:first-child) {
        margin-left: 20px;
      }
    }

    a {
      transition-property: color, background-color, border-color;
      transition-duration: 0.2s;
      transition-timing-function: linear;

      color: $sp-primary-text-color;

      &:hover {
        color: $sp-primary-color;
      }
    }

    form {
      .p-calendar input,
      .p-inputnumber input,
      .p-password input,
      .p-multiselect-label,
      .p-dropdown .p-inputtext {
        padding-left: 40px;
      }

      .form-row {
        margin-bottom: 30px;
      }

      i {
        z-index: 1; 
      }

      .active-panel {
        display: flex;
        align-items: center;
        column-gap: 10px;
      }

      .required {
        & + label {
          &::after {
            content: "*";
            display: block;
            position: absolute;
            right: -7px;
            top: 0;
            color: $sp-primary-color;
          }
        }
      }
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  #controlpanel {
    display: block;

    .content {
      width: 100%;
      margin: 0;

      .title-panel {
        flex-direction: column;
        justify-content: center;
        align-items: space-between;
      }
    }
  }
}
</style>
