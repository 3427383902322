<template>
  <div v-if="isAdmin" class="admin-panel">
    <router-link to="/controlpanel">zum Control Panel</router-link>
    <small>(nur für Administratoren sichtbar)</small>
  </div>

  <nav>
    <router-link to="/">
      <img class="logo" :src="require('@/assets/img/logo-250x250.png')" alt="Sälipark" />
    </router-link>
  </nav>
</template>

<script lang="ts">
import { useUserStatusStore } from "@/stores/userStatusStore";
import { defineComponent, ref } from "vue";

/**
 * Component used to render the navigation items in the Web Front.
 */
export default defineComponent({
  name: "WebFrontHeader",
  components: {},
  setup() {
    const userStatusStore = useUserStatusStore();

    const isAdmin = userStatusStore.isAdmin;

    return {
      isAdmin
    };
  },
});
</script>

<style scoped lang="scss">
.admin-panel {
  font-family: Arial, sans-serif !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $sp-primary-color;
  padding: 10px;
  color: white;

  a {
    color: white;
  }
}

nav {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;

  a {
    width: 30%;
    max-width: 150px;

    img {
      width: 100%;
    }
  }
}
</style>
