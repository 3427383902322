import { ModelDto } from "./base/ModelDto";
import { TypeMapping } from "./shared/TypeMapping";
import { TypeHelper } from "@/helpers/TypeHelper";
import { ListDto } from "./base/ListDto";
import { ParticipationModel } from "@/models/ParticipationModel";

class ParticipationDto extends ModelDto<ParticipationModel> {
  public static readonly mappings: TypeMapping[] = [
    {
      key: "model",
      cast: (x) => TypeHelper.mappers.ParticipationModel(x),
    },
  ];
}

class ParticipationListDto extends ListDto<ParticipationDto> {
  public static readonly mappings: TypeMapping[] = [
    {
      key: "list",
      cast: (x) => x.map((y: any) => TypeHelper.mappers.ParticipationDto(y)),
    },
  ];
}

export { ParticipationDto, ParticipationListDto };
