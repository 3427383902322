<template>
  <Menubar :model="items" :unstyled="true" class="menubar">
    <template #item="{ item }">
      <template v-if="item.url">
        <span :class="item.icon"></span>
        <router-link v-if="item.isExact" exact-active-class="active" :to="item.url">{{ item.label }}</router-link>
        <router-link v-else active-class="active" :to="item.url">{{ item.label }}</router-link>
      </template>
      <template v-else>{{ item.label }}.url missing</template>
    </template>
  </Menubar>
</template>

<script lang="ts">
import { defineComponent, toRefs } from "vue";
import { MenuItem } from "primevue/menuitem";

/**
 * Component used to render a single menubar item inside a navigation.
 */
export default defineComponent({
  name: "LoadingSkeleton",
  props: {
    items: Array<MenuItem>,
  },
  setup(props) {
    const { items } = toRefs(props);

    return { items };
  },
});
</script>

<style scoped lang="scss">
.menubar {
  :deep(> a) {
    display: none;
  }

  :deep(ul) {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      padding-left: 15px;
      padding: 10px;

      &:has(a.active) {
        background: $sp-controlpanel-background-color;
        border-radius: $borderRadius;
      }

      span.pi:has(+ a.active) {
        color: $sp-primary-color;
        fill: $sp-primary-color;
        font-weight: bold;
      }

      a {
        color: $sp-controlpanel-foreground-color;
        text-decoration: none;
        margin-left: 5px;

        &:active,
        &:focus-visible {
          color: $sp-primary-color;
        }

        &.active {
          font-weight: bold;
        }
      }
    }
  }
}
</style>
