/**
 * Helper methods related to routing.
 */
export default {
  /* -------------------------------------------
  Returns the parameter if it's a valid integer.
  ------------------------------------------- */
  tryGetParam(param: string | string[], errorCallback: () => void): number {
    const id = Number(param);
    if (!Number.isInteger(id)) {
      errorCallback();
    }
    return id;
  },
};
