<template>
  <div class="title-panel">
    <h2>Feedback einsehen</h2>
  </div>

  <FeedbacksCreateEdit :id="id" :dto="dto"></FeedbacksCreateEdit>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { FeedbackDto } from "@/dtos/FeedbackDtos";
import { FormHelper } from "@/helpers/FormHelper";
import routerMixins from "@/mixins/routerMixins";
import FeedbacksCreateEdit from "@/components/controlpanel/feedbacks/FeedbacksCreateEdit.vue";
import { FeedbackModel } from "@/models/FeedbackModel";
import { useFeedbackStore } from "@/stores/feedbackStore";

/**
 * View that renders the the "edit" form for feedbacks.
 */
export default defineComponent({
  name: "FeedbacksEdit",
  setup() {
    const store = useFeedbackStore();
    const route = useRoute();

    const formHelper = new FormHelper("/controlpanel/feedbacks");

    // validate parameter.
    const id = routerMixins.tryGetParam(route.params.id, () => {
      formHelper.returnToList();
    });

    const dto = ref(new FeedbackDto(new FeedbackModel()));

    // get entity from store.
    onMounted(() => {
      store.getFeedback(dto.value, id);
    });

    return {
      id,
      dto,
    };
  },
  components: { FeedbacksCreateEdit },
});
</script>

<style scoped lang="scss"></style>
