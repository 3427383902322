import { BaseListDto } from "@/dtos/base/BaseListDto";
import { SortDirection } from "@/enums/SortDirection";
import { ApiHelper } from "@/helpers/ApiHelper";
import Axios, { AxiosResponse } from "axios";

export class ApiControllerCore {
  protected _internalGet<T>(url: string): Promise<AxiosResponse<T>> {
    return new Promise<AxiosResponse<T>>((resolve, reject) => {
      Axios.get(url)
        .then((response) => {
          ApiHelper.processResponse(response).then(resolve).catch(reject);
        })
        .catch(reject);
    });
  }

  protected _internalPost<S, T>(url: string, data: S): Promise<AxiosResponse<T>> {
    return new Promise<AxiosResponse<T>>((resolve, reject) => {
      Axios.post(url, data)
        .then((response) => {
          ApiHelper.processResponse(response).then(resolve).catch(reject);
        })
        .catch(reject);
    });
  }

  protected _internalPut<S, T>(url: string, data: S): Promise<AxiosResponse<T>> {
    return new Promise<AxiosResponse<T>>((resolve, reject) => {
      Axios.put(url, data)
        .then((response) => {
          ApiHelper.processResponse(response).then(resolve).catch(reject);
        })
        .catch(reject);
    });
  }

  protected _internalDelete<T>(url: string): Promise<AxiosResponse<T>> {
    return new Promise<AxiosResponse<T>>((resolve, reject) => {
      Axios.delete(url)
        .then((response) => {
          ApiHelper.processResponse(response).then(resolve).catch(reject);
        })
        .catch(reject);
    });
  }

  protected createUrlQueryForList(dto: BaseListDto) {
    let params: string[] = [];

    if (dto.filter !== null) params.push("filter=" + dto.filter);
    if (dto.typeFilter !== null) params.push("typeFilter=" + dto.typeFilter);
    if (dto.sortColumn !== null) params.push("sortColumn=" + dto.sortColumn.replaceAll("model.", ""));
    if (dto.sortDirection !== SortDirection.NONE) params.push("sortDirection=" + dto.sortDirection);
    if (dto.page >= 0) params.push("page=" + dto.page);

    let paramsJoined = params.join("&");

    let urlQuery = "";
    if (paramsJoined !== "") {
      urlQuery = "?" + paramsJoined;
    }
    return urlQuery;
  }
}
