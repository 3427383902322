<template>
  <h1>{{ Translator.translate("WEBFRONT_LANDINGPAGE_TITLE") }}</h1>

  <p class="m-0">{{ Translator.translate("WEBFRONT_LANDINGPAGE_RUNNINGTEXT1") }}</p>
  <p>{{ Translator.translate("WEBFRONT_LANDINGPAGE_RUNNINGTEXT2") }}</p>

  <form @submit.prevent="" @change="validator.validateForm(form as ParticipationEntryFormData, dto)">
    <div class="form-row">
      <span class="p-float-label">
        <InputText
          type="tel"
          id="phoneNumber"
          v-on:keyup.enter="participate()"
          :disabled="dto.isLoading"
          v-model="form.phoneNumber"
          :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'phoneNumber') }"
        />
        <label for="phoneNumber">{{ Translator.translate("WEBFRONT_LANDINGPAGE_INPUT_PHONENUMBER") }}</label>
      </span>
      <small class="p-error" v-show="validator.hasValidationErrors(dto, 'phoneNumber')">{{ Translator.translate("WEBFRONT_LANDINGPAGE_INPUT_PHONENUMBER_VALIDATION") }}</small>
    </div>

    <Button :label="Translator.translate('WEBFRONT_SUBMIT_BUTTON')" @click="participate()" :loading="dto.isLoading"></Button>
  </form>

  <Message v-if="dto.hasError" severity="error" :closable="false">{{ Translator.translate("WEBFRONT_LANDINGPAGE_ERROR") }}</Message>

  <p class="mt-10"><Translate text="WEBFRONT_LANDINGPAGE_ADDITIONAL_INFO1"></Translate></p>
  <p><Translate text="WEBFRONT_LANDINGPAGE_ADDITIONAL_INFO2"></Translate></p>
  <p>
    <router-link to="/terms-and-conditions">{{ Translator.translate("WEBFRONT_LANDINGPAGE_TERMS_AND_CONDITIONS") }}</router-link>
  </p>
</template>

<script lang="ts">
import { ParticipationDto } from "@/dtos/ParticipationDtos";
import { ParticipationEntryFormData } from "@/dtos/data/ParticipationEntryFormData";
import { FormHelper } from "@/helpers/FormHelper";
import { Translator } from "@/helpers/Translator";
import { Validator } from "@/helpers/Validator";
import { ParticipationModel } from "@/models/ParticipationModel";
import { defineComponent, ref } from "vue";
import Translate from "@/components/shared/Translate.vue";
import { IParticipationEntryModel } from "@/models/interfaces/IParticipationEntryModel";
import { useParticipationStore } from "@/stores/participationStore";
import { ObjectHelper } from "@/helpers/ObjectHelper";
import { ToastMessageOptions } from "primevue/toast";
import { useToast } from "primevue/usetoast";
import { ParticipationResultViewModel } from "@/viewModels/ParticipationResultViewModel";
import { ParticipationResult } from "@/enums/ParticipationResult";
import { AxiosResponse } from "axios";
import { StatusCode } from "@/enums/StatusCode";

export default defineComponent({
  name: "WebFrontLandingPage",
  components: {
    Translate,
  },
  methods: {
    participate() {
      const participationStore = useParticipationStore();

      this.form.shouldValidate = true;

      // update values in store.
      ObjectHelper.copyExistingPropsFromTo(this.form, this.dto.model);

      this.validator.validateForm(this.form as ParticipationEntryFormData, this.dto).then(() => {
        return participationStore
          .createParticipation(this.dto as ParticipationDto)
          .then((response: ParticipationResultViewModel) => {
            if (response.participationResult === ParticipationResult.WON) {
              this.$router.push("/result-won");
            } else {
              this.$router.push("/result-lost");
            }
          })
          .catch((error: AxiosResponse<ParticipationResultViewModel>) => {
            switch (error.data.statusCode) {
              case StatusCode.PARTICIPATION_PHONE_ALREADY_USED:
                this.$router.push("/result-rejected");
                break;
            }
          });
      });
    },
  },
  setup() {
    const toast = useToast();

    const validator = new Validator<IParticipationEntryModel>();
    const formHelper = new FormHelper("/");

    const dto = ref(new ParticipationDto(new ParticipationModel()));
    const form = ref(new ParticipationEntryFormData(dto.value));

    const showToast = (toastOptions: ToastMessageOptions) => {
      toast.add(toastOptions);
    };

    return {
      validator,
      formHelper,
      dto,
      form,
      Translator,
      showToast,
    };
  },
});
</script>

<style scoped lang="scss">
.mt-10 {
  margin-top: 100px;
}

form {
  margin-top: 10px;
}
</style>
