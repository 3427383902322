import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-70bde489"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-row" }
const _hoisted_2 = { class: "p-float-label" }
const _hoisted_3 = { for: "response1" }
const _hoisted_4 = { class: "form-row" }
const _hoisted_5 = { class: "p-float-label" }
const _hoisted_6 = { for: "response2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Message = _resolveComponent("Message")!
  const _component_Button = _resolveComponent("Button")!

  return (_ctx.isFeedbackGivenSuccessfully)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.Translator.translate("WEBFRONT_FEEDBACK_TITLE")), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.Translator.translate("WEBFRONT_FEEDBACK_DESCRIPTION")), 1)
      ], 64))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_TITLE")), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.Translator.translate("WEBFRONT_RESULT_WON_CONFIRM_DESCRIPTION")), 1),
        _createElementVNode("form", {
          onSubmit: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"]))
        }, [
          _createElementVNode("p", null, _toDisplayString(_ctx.Translator.translate("WEBFRONT_FEEDBACK_INPUT_PROMPT1")), 1),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("span", _hoisted_2, [
              _createVNode(_component_InputText, {
                type: "text",
                id: "response1",
                disabled: _ctx.dto.isLoading,
                modelValue: _ctx.form.response1,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.response1) = $event))
              }, null, 8, ["disabled", "modelValue"]),
              _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.Translator.translate("WEBFRONT_FEEDBACK_INPUT_NAME1")), 1)
            ]),
            _withDirectives(_createElementVNode("small", { class: "p-error" }, _toDisplayString(_ctx.Translator.translate("WEBFRONT_FEEDBACK_INPUT_VALIDATION")), 513), [
              [_vShow, _ctx.validator.hasValidationErrors(_ctx.dto, 'response1')]
            ])
          ]),
          _createElementVNode("p", null, _toDisplayString(_ctx.Translator.translate("WEBFRONT_FEEDBACK_INPUT_PROMPT2")), 1),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, [
              _createVNode(_component_InputText, {
                type: "text",
                id: "response2",
                disabled: _ctx.dto.isLoading,
                modelValue: _ctx.form.response2,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.response2) = $event))
              }, null, 8, ["disabled", "modelValue"]),
              _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.Translator.translate("WEBFRONT_FEEDBACK_INPUT_NAME2")), 1)
            ]),
            _withDirectives(_createElementVNode("small", { class: "p-error" }, _toDisplayString(_ctx.Translator.translate("WEBFRONT_FEEDBACK_INPUT_VALIDATION")), 513), [
              [_vShow, _ctx.validator.hasValidationErrors(_ctx.dto, 'response2')]
            ])
          ]),
          (_ctx.dto.hasError)
            ? (_openBlock(), _createBlock(_component_Message, {
                key: 0,
                severity: "error",
                closable: false
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.Translator.translate("WEBFRONT_FEEDBACK_SUBMIT_ERROR")), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_Button, {
            label: _ctx.Translator.translate('WEBFRONT_SUBMIT_BUTTON'),
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.submitFeedback())),
            loading: _ctx.dto.isLoading
          }, null, 8, ["label", "loading"])
        ], 32)
      ], 64))
}